import { Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import GeneralService from '../../../../services/GeneralService';
import { useForm } from 'react-hook-form';
import { HIDE } from '../../../../constant/constant';
import { hideLoading, showLoading } from '../../../../reducers/main/toastProgressSlice';
import { convertBase64ToArrayBuffer, downloadFileFromArrayBuffer, MessageError } from '../../../../utils';

export const ModalExportFiles = ({ show, setShow }) => {
    const { listDocuments } = useSelector(state => state.documentsAutoSlice);
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const dispatch = useDispatch();

    const onSubmit = async (data) => {
        const service = new GeneralService(data.download_format === "zip" ? "generate-document/download" : "document-report");
        dispatch(showLoading("Descarga en progreso"));
        const res = await service.getRequest(data);
        dispatch(hideLoading());
        if (res.is_ok) {
            const arrayBuffer = convertBase64ToArrayBuffer(res.file);
            downloadFileFromArrayBuffer(arrayBuffer, "SortomExport." + data.download_format);
            return;
        }
        MessageError(res.message, service.status);
    }

    return (
        <Modal show={show} onHide={() => setShow(HIDE)}>
            <form onSubmit={handleSubmit(onSubmit)} autoCorrect="on">
                <Modal.Header className='bg-light-green pe-4' closeButton>
                    <i className="fa-solid fa-file-export animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                    <h5 className="d-inline-block mt-2 ms-2 me-1">Exportar documentos</h5>
                </Modal.Header>
                <Modal.Body>
                    <form className="card-body">
                        <div className="row m-0 p-0">
                            <div className='mb-3'>
                                <label>Plantilla</label>
                                <select
                                    className="form-select green-input"
                                    {...register('documents_auto_id', { required: 'Plantilla requerida' })}
                                >
                                    <option value="">
                                        Selecciona...
                                    </option>
                                    {
                                        listDocuments.map(document => <option value={document.id} key={document.id}>
                                            {document.name}
                                        </option>)
                                    }
                                </select>
                                {errors.documents_auto_id && <div className="fs-7 text-red text-end">{errors.documents_auto_id.message}</div>}
                            </div>

                            <div className='mb-3'>
                                <label className="font-rubik">Fecha de inicio<b><i className="text-red">*</i></b></label>
                                <input
                                    type="date"
                                    className="form-control green-input"
                                    {...register('initial_date', { required: 'Fecha de inicio es requerida' })}
                                />
                                {errors.initial_date && <div className="fs-7 text-red text-end">{errors.initial_date.message}</div>}
                            </div>

                            <div className='mb-3'>
                                <label className="font-rubik">Fecha de finalización<b><i className="text-red">*</i></b></label>
                                <input
                                    type="date"
                                    className="form-control green-input"
                                    {...register('finish_date', { required: 'Fecha de inicio es requerida' })}
                                />
                                {errors.finish_date && <div className="fs-7 text-red text-end">{errors.finish_date.message}</div>}
                            </div>

                            <div className='mb-3'>
                                <label className="font-rubik">Elige el formato de descarga<b><i className="text-red">*</i></b>
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={
                                            <Popover id={`popover-positioned`}>
                                                <Popover.Header as="h3" className='bg-light-green text-center'>
                                                    Formato de descarga
                                                </Popover.Header>
                                                <Popover.Body>
                                                    <h6><i className="fa-solid fa-file-excel text-green me-1"></i> Excel</h6>
                                                    <p>Crea un reporte de los documentos generados en formato excel, con la estructura del tipo de documento seleccionado y sus respectivos valores.</p>
                                                    <h6><i className="fa-solid fa-file-zipper text-green me-1"></i> Zip</h6>
                                                    <p>Permite descargar en un archivo comprimido los PDFs generados de los documentos de acuerdo con el rango de fechas y tipo de documento.</p>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <i className="fa-solid fa-circle-question ms-2 text-light-purple" role='button'></i>
                                    </OverlayTrigger>
                                </label>

                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        id="flexRadioDefault1"
                                        value="xlsx"
                                        {...register('download_format', { required: 'Formato de descarga es requerido' })}
                                    />
                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                        <i className="fa-solid fa-file-excel text-green me-1"></i>
                                        Excel
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        id="flexRadioDefault2"
                                        value="zip"
                                        {...register('download_format', { required: 'Formato de descarga es requerido' })}
                                    />
                                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        <i className="fa-solid fa-file-zipper text-green me-1"></i>
                                        Zip
                                    </label>
                                </div>
                                {errors.download_format && <p className="text-danger">{errors.download_format.message}</p>}
                            </div>

                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className={`btn btn-success mx-auto rounded-3 button-save-document'`}>
                        <i className="fa-solid fa-cloud-arrow-down me-2"></i>
                        Descargar
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}