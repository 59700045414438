import { createSlice } from '@reduxjs/toolkit';

export const calendarslice = createSlice({
    name: 'calendarslice',
    initialState: {
        labels: [],
        events: [],
        eventsday: [],
        colors: [],
        user: [],
        oncelabel: [],
        event: {
            title: "",
            description: "",
            start_date: "",
            end_date: "",
            notification_time: "",
            interval: 1,
            reporting_id: 0,
            responsible_id: 0,
            label_id: 0,
        },
        eventupdate: {
            title: "",
            description: "",
            start_date: "",
            end_date: "",
            notification_time: "",
            interval: 1,
            reporting_id: 0,
            responsible_id: 0,
            label_id: 0,
        },
        day: Date.now(),
        label: {
            name: "",
            label_color_id: 0
        },
        filters: {
            label_id: 0,
            start_date: "",
            end_date: ""
        }
    },
    reducers: {
        setListLabels: (state, { payload }) => {
            state.labels = payload;
        },
        addToListLabels: (state, { payload }) => {
            state.labels.push(payload);
        },
        removeFromListLabels: (state, { payload }) => {
            state.labels = state.labels.filter(item => item.id !== payload);
        },
        setListLabel: (state, action) => {
            state.oncelabel = action.payload.value;
        },
        setListColors: (state, action) => {
            state.colors = action.payload.value;
        },
        setListUser: (state, action) => {
            state.user = action.payload.value;
        },
        setEventUpdate: (state, action) => {
            state.eventupdate[action.payload.key] = action.payload.value;
        },
        eventUpdate: (state, { payload }) => {
            state.event = payload;
        },
        setEvents: (state, { payload }) => {
            state.events = payload;
        },
        addToListEvents: (state, { payload }) => {
            state.events.push(payload);
        },
        updateFromListEvents: (state, { payload }) => {
            state.events = state.events.map(item => {
                if (item.id === payload.id) return payload;
                return item;
            });
            state.eventsday = state.eventsday.map(item => {
                if (item.id === payload.id) return payload;
                return item;
            });
        },
        deleteFromListEvents: (state, { payload }) => {
            state.events = state.events.filter(item => item.id !== payload);
            state.eventsday = state.eventsday.filter(item => item.id !== payload);
        },
        setEventsday: (state, action) => {
            state.eventsday = action.payload.value;
        },
        setEvent: (state, action) => {
            state.event[action.payload.key] = action.payload.value;
        },
        setFilters: (state, { payload }) => {
            if (payload.key === "label_id" && state.filters[payload.key] === payload.value) {
                state.filters[payload.key] = 0;
            } else {
                state.filters[payload.key] = payload.value;
            }
        },
        setEventData: (state, action) => {
            state.event[action.payload.key] = action.payload.value;
        },

        setLabel: (state, { payload }) => {
            state.label[payload.key] = payload.value;
        },

        setCleanLabel: (state, action) => {
            state.label = {
                name: "",
                label_color_id: 0
            }
        },
        setCleanEvent: (state, action) => {
            state.event = {
                id: 0,
                title: "",
                description: "",
                start_date: state.event.start_date,
                end_date: "",
                notification_time: "",
                interval: 1,
                reporting_id: 0,
                responsible_id: 0,
                label_id: 0,
            }
        }
    },
});

export const {
    setListLabels,
    addToListLabels,
    removeFromListLabels,
    setEvents,
    addToListEvents,
    updateFromListEvents,
    deleteFromListEvents,
    setEvent,
    setEventsday,
    setLabel,
    setListColors,
    saveLabel,
    setCleanLabel,
    eventUpdate,
    setListUser,
    setFilters,
    setListLabel,
    setEventUpdate,
    setCleanEvent
} = calendarslice.actions;

export default calendarslice.reducer;