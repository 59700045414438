import { NavDropdown } from "react-bootstrap";

const DrodownNavigation = ({ icon = "", title = "", isExpanded, isMobile, children, id }) => {
    return (
        <NavDropdown
            id={id}
            className={`menu-dropdown ${!isExpanded && !isMobile ? "hide-arrow": ""} ${!isExpanded ? "not-expanded": ""}`}
            title={
                isMobile
                    ?
                    <span className="text-white">
                        <i className={`${icon} fs-5 icon__menu`}></i> {title}
                    </span>
                    :
                    isExpanded
                        ?
                        <span className="text-white">
                            <i className={`${icon} fs-5 icon__menu ms-2`}></i> {title}
                        </span>
                        :
                        <i className={`${icon} fs-5 icon__menu`}></i>
            }
        >
            {children}
        </NavDropdown>
    )
}

export default DrodownNavigation;