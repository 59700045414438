import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { clearFilter, reducerForm } from "../../../reducers/people/reducerPersonDocument";
import GeneralService from "../../../services/GeneralService";
import { setListDocuments } from "../../../reducers/documents/reducerGenerateDocument";
import ModalFilterByPerson from "./ModalFilterByPerson";
import { HIDE, SHOW } from "../../../constant/constant";
import { ModalExportFiles } from "../../DocumentsAuto/components/noSigned/ModalExportFiles";

const ToolFilterDocuments = ({ isPerPerson = false, showExportButton = HIDE, onReload = () => { } }) => {
    const { dataFilter } = useSelector(state => state.personDocumentSlice);
    const { listDocuments } = useSelector(state => state.documentsAutoSlice);
    const [show, setShow] = useState(false);
    const [showExportFiles, setShowExportfiles] = useState(HIDE);
    const dispatch = useDispatch();

    const setData = (e) => {
        dispatch(reducerForm({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    const loadTemplates = async () => {
        const service = new GeneralService("documents-auto");
        const { documents = [] } = await service.getList(1000);
        dispatch(setListDocuments({ value: documents }));
    }

    useEffect(() => {
        loadTemplates();
    }, []);

    return (
        <>
            <div className="row px-2 pt-3 animate__animated animate__fadeInDown">

                <div className="col-12 col-md-4 col-lg-2">
                    <label>Tipo de documento</label>
                    <select
                        className="form-select green-input"
                        name="type_document"
                        value={dataFilter.type_document}
                        onChange={setData}
                    >
                        <option value="0">
                            Selecciona...
                        </option>
                        {
                            listDocuments.map(document => <option value={document.id} key={document.id}>
                                {document.name}
                            </option>)
                        }
                    </select>
                </div>
                <div className="col-12 col-md-4 col-lg-2">
                    <label>Fecha</label>
                    <input
                        type="date"
                        className="form-control green-input"
                        name="date_sent"
                        placeholder="Digita una fecha"
                        value={dataFilter.date_sent}
                        maxLength="50"
                        onChange={setData}
                    />
                </div>
                <div className="col-12 col-md-4 col-lg-2">
                    <label>Estado</label>
                    <select
                        className="form-select green-input"
                        name="status"
                        value={dataFilter.status}
                        onChange={setData}
                    >
                        <option value="">
                            Selecciona el estado
                        </option>
                        <option value="signed">
                            FIRMADO
                        </option>
                        <option value={isPerPerson ? "new" : "pending"}>
                            NO FIRMADO
                        </option>
                    </select>
                </div>
                {
                    !isPerPerson && <div className="col-12 col-lg-6 d-flex justify-content-right">
                        <button title="Filtrar por personas" className="btn btn-success mt-4 ms-auto" type="button" onClick={() => setShow(true)}>
                            <i className="fa-solid fa-person-rays text-white me-2" />
                            Personas
                        </button>
                        <button title="Quitar filtros" className="btn btn-success rounded-pill px- py-1 ms-1 mt-4" type="button" onClick={() => dispatch(clearFilter())}>
                            <i className="fa-solid fa-filter-circle-xmark text-white"></i>
                        </button>
                        {
                            showExportButton
                            &&
                            <button title="Exportar" className="btn btn-success rounded-pill px- py-1 mx-1 mt-4" type="button" onClick={() => setShowExportfiles(SHOW)}>
                                <i className="fa-solid fa-file-export text-white"></i>
                            </button>
                        }
                        <button title="Recargar" className="btn btn-success rounded-pill ms-1 mt-4" type="button" onClick={onReload}>
                            <i className="fa-solid fa-rotate text-white"></i>
                        </button>
                    </div>
                }

            </div>
            <ModalFilterByPerson show={show} setShow={setShow} />
            <ModalExportFiles show={showExportFiles} setShow={setShowExportfiles} />
        </>
    )
}

export default ToolFilterDocuments;