import { Modal } from "react-bootstrap";

import { ColorPicker } from "./index";

import GeneralService from "../../../services/GeneralService";
import { useDispatch, useSelector } from "react-redux";
import { MessageError, toast } from "../../../utils";
import { TOAST_SUCCESS, HIDE, TOAST_DANGER } from "../../../constant/constant";
import { setLabel, setCleanLabel, addToListLabels } from "../../../reducers/calendar/reducerCalendar";

export const ModalLabel = ({ show, setShow }) => {
  const { label } = useSelector(state => state.calendarslice);
  const dispatch = useDispatch();

  const labelSubmit = async (e) => {
    e.preventDefault();
    const service = new GeneralService("labels");

    if (!label.label_color_id) return toast(dispatch, "No has seleccionado un color", TOAST_DANGER);

    const res = await service.post(label);
    if (res.is_ok) {
      toast(dispatch, res.message, TOAST_SUCCESS, 5000);
      dispatch(addToListLabels(res.content));
      dispatch(setCleanLabel());
      setShow(HIDE);
      return;
    }

    MessageError(res.message);
  }

  const setData = (e) => {
    dispatch(setLabel({
      key: e.target.name,
      value: e.target.value,
    }));
  }

  return (
    <Modal show={show} onHide={() => setShow(false)} size='md'>
      <Modal.Header className='bg-light-green pe-4' closeButton>
        <i className="fa-solid fa-circle-plus animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
        <h5 className="mt-2 ms-2">Crear etiqueta</h5>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={labelSubmit}>
          <div className="divinput">
            <i className="fa-solid fa-file-pen text-green"></i>
            <input
              className="form-control green-input"
              type="text"
              placeholder="Nombre de la categoría"
              name="name"
              value={label.name}
              onChange={setData}
              autoComplete="off"
              required
            />
          </div>

          <label className='font-lato fw-bold my-2'>Elige un color</label>
          <ColorPicker className="color-pick" />

          <div className="divbotones">
            <button type="submit" title="Agregar" className="btn btn-success">
              <i className="fa-solid fa-paper-plane text-white me-2" />
              Guardar
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
