import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import Select from "react-select";

import GeneralService from "../../../services/GeneralService";
import { MessageError, toast } from "../../../utils";
import { setEvent, addToListEvents, updateFromListEvents } from "../../../reducers/calendar/reducerCalendar";
import { downloadImageAsBase64 } from "../../../firebase/firebaseStorage";
import { reactSelectStyles } from "../../Expedients/generate/styles/reactSelectStyles";
import { TOAST_DANGER, HIDE, ID_NOT_DEFINED } from "../../../constant/constant";

export const ModalEvent = ({ show, setShow }) => {
  const { labels, user, event } = useSelector(state => state.calendarslice);
  const [optionsUser, setOptionsUser] = useState([]);
  const [optionsLabelCalendar, setOptionsLabelCalendar] = useState([]);
  const dispatch = useDispatch();

  const formatOptionResponsible = ({ avatar, label, email }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        src={avatar}
        alt={label}
        style={{ width: 30, height: 30, borderRadius: "50%", marginRight: 10 }}
      />
      <div>
        <div>{label}</div>
        <div style={{ fontSize: "12px", color: "#888" }}>{email}</div>
      </div>
    </div>
  )

  const formatOptionLabelCalendar = ({ label, color, background }) => (
    <div style={{ display: "flex", alignItems: "center", backgroundColor: background, color, padding: "5px 10px", borderRadius: "4px", justifyContent: "center" }}>
      {label}
    </div>
  )

  const handleChange = (event) => {
    const { name, value } = event.target;
    dispatch(setEvent({ key: name, value }));
  }

  const handleSelectChange = (selectedOption, actionMeta) => {
    dispatch(setEvent({ key: actionMeta.name, value: selectedOption.value }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (new Date(event.start_date) > new Date(event.end_date)) {
      return toast(dispatch, "Fecha inicial mayor a la final", TOAST_DANGER);
    }

    const updatedEvent = {
      ...event,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    const service = new GeneralService("events");

    if (event.id === ID_NOT_DEFINED) {
      const res = await service.post(updatedEvent);
      if (res.is_ok) {
        toast(dispatch, res.message);
        dispatch(addToListEvents(res.content));
        setShow(HIDE);
        return
      }
      return MessageError(res.message);
    }

    const res = await service.update(updatedEvent);
    if (res.is_ok) {
      toast(dispatch, res.message);
      dispatch(updateFromListEvents(res.content));
      setShow(HIDE);
      return
    }
    MessageError(res.message);
  }

  const loadOptions = async () => {
    const options = [];
    for (const item of user) {
      const base64 = await downloadImageAsBase64(item.url_avatar);

      options.push({
        value: item.id,
        label: item.name,
        avatar: base64,
        email: item.user.email,
      });
    }
    setOptionsUser(options);
  }

  const loadOptionsLabelCalendar = async () => {
    const options = labels.map(item => ({
      value: item.id,
      label: item.name,
      color: item.label_color.color_font,
      background: item.label_color.color
    }));

    setOptionsLabelCalendar(options);
  }

  useEffect(() => {
    loadOptions();
  }, [user]);

  useEffect(() => {
    loadOptionsLabelCalendar();
  }, [labels]);

  return (
    <Modal show={show} onHide={() => setShow(HIDE)} size='lg'>
      <Modal.Header className='bg-light-green pe-4' closeButton>
        {
          event.id === ID_NOT_DEFINED
            ?
            <i className="fa-solid fa-circle-plus animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
            :
            <i className="fa-solid fa-pen-to-square animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>

        }
        <h5 className="mt-2 ms-2">{event.id === ID_NOT_DEFINED ? "Crear evento" : "Editar evento"}</h5>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-1 d-flex align-items-center" style={{ width: "26px" }}>
              <i className="fa-solid fa-tag text-green" />
            </div>
            <div className="col">
              <Select
                value={optionsLabelCalendar.find(option => option.value === event.label_id)}
                onChange={handleSelectChange}
                options={optionsLabelCalendar}
                formatOptionLabel={formatOptionLabelCalendar}
                name="label_id"
                placeholder="Seleccione..."
                styles={reactSelectStyles}
              />
            </div>
            <div className="col-1 d-flex align-items-center" style={{ width: "26px" }}>
              <i className="fa-solid fa-bell text-green" />
            </div>
            <div className="col">
              <select
                name="notification_time"
                className="form-select green-input w-100"
                value={event.notification_time}
                onChange={handleChange}
                required
              >
                <option selected className="dropdown-item" value="">
                  Notificación
                </option>
                <option className="dropdown-item" value="15M">
                  15 minutos
                </option>
                <option className="dropdown-item" value="30M">
                  30 minutos
                </option>
                <option className="dropdown-item" value="1H">
                  1 hora
                </option>
                <option className="dropdown-item" value="5H">
                  5 horas
                </option>
                <option className="dropdown-item" value="1D">
                  1 día
                </option>
                <option className="dropdown-item" value="1W">
                  1 semana
                </option>
              </select>
            </div>
          </div>

          <div className="divinput mt-3">
            <i className="fa-solid fa-file-pen text-green" />
            <input
              className="form-control green-input"
              type="text"
              placeholder="Titulo del evento"
              name="title"
              value={event.title}
              onChange={handleChange}
              required
              autoComplete="off"
            />
          </div>

          <div className="row mt-3">
            <div className="col-1 d-flex align-items-center" style={{ width: "26px" }}>
              <i className="fa-solid fa-user-plus text-green" />
            </div>
            <div className="col pe-0">
              <Select
                value={optionsUser.find(option => option.value === event.responsible_id)}
                onChange={handleSelectChange}
                options={optionsUser}
                formatOptionLabel={formatOptionResponsible}
                name="responsible_id"
                placeholder="Responsable del evento"
                styles={reactSelectStyles}
              />
            </div>
            <div className="col">
              <Select
                value={optionsUser.find(option => option.value === event.reporting_id)}
                onChange={handleSelectChange}
                options={optionsUser}
                formatOptionLabel={formatOptionResponsible}
                name="reporting_id"
                placeholder="Informador del evento"
                styles={reactSelectStyles}
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col">
              <div className="divinput">
                <i className="fa-solid fa-clock text-green"></i>
                <input
                  className="form-control green-input"
                  type="datetime-local"
                  name="start_date"
                  value={event.start_date}
                  onChange={handleChange}
                  required
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="col">
              <div className="divinput">
                <i className="fa-solid fa-clock text-green"></i>
                <input
                  className="form-control green-input"
                  type="datetime-local"
                  name="end_date"
                  value={event.end_date}
                  onChange={handleChange}
                  required
                  autoComplete="off"
                />
              </div>
            </div>
          </div>

          <div className="divinput mt-3">
            <i className="fa-solid fa-align-justify text-green"></i>
            <textarea
              className="form-control textarea-style green-input"
              placeholder="Agrega una descripción..."
              name="description"
              value={event.description}
              onChange={handleChange}
              required
              autoComplete="off"
            />
          </div>

          <div className="divbotones">
            <button type="submit" title="Agregar" className="btn btn-success">
              <i className="fa-solid fa-paper-plane text-white me-2" />
              Guardar
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
