import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import ChangePassword from "../modules/Login/components/ChangePassword";
import ToastNotify from "../app/components/ToastNotify";
import { LoginView } from "../modules/Login/components/LoginView";

import { useSelector } from "react-redux";

const GeneralRoutes = () => {
    const { show } = useSelector(state => state.toastSlice);

    return (
        <div>
            {show && <ToastNotify />}

            <Router>
                <Switch>
                    <Route exact path="/auth/login"> <LoginView /> </Route>

                    <Route exact path="/auth/change-password/:uuid"> <ChangePassword /> </Route>
                </Switch>
            </Router>
        </div>
    )
}

export default GeneralRoutes;