import { OverlayTrigger, Popover } from "react-bootstrap";

import IconButton from "../../../app/components/IconButton";
import { formatDateTime } from "../../../utils";

export const ItemEvent = ({ event, onEdit, onDelete }) => {

    const formatTime = (timeString) => {
        if (!timeString) return "";

        const [hours, minutes] = timeString.split(":").map(Number);
        const ampm = hours >= 12 ? "PM" : "AM";
        const formattedHours = hours % 12 || 12; // convierte a formato 12 horas
        const formattedTime = `${formattedHours}:${minutes < 10 ? "0" + minutes : minutes} ${ampm}`;

        return formattedTime;
    }

    const popover = (
        <Popover style={{ minWidth: "20rem", maxHeight: "25rem", overflowY: "auto", overflowX: "hidden" }}>
            <Popover.Header className="bg-green text-white text-center" as="h3">Detalles</Popover.Header>
            <Popover.Body className="d-flex flex-column">
                <div>
                    <i className="fa-solid fa-user text-green me-2" />
                    <span className="fw-bold">Responsable:</span> {event.responsible.name}
                </div>
                <div>
                    <i className="fa-solid fa-user text-green me-2" />
                    <span className="fw-bold">Reportador:</span> {event.reporting.name}
                </div>
                <div>
                    <i className="fa-solid fa-calendar text-green me-2" />
                    <span className="fw-bold">Fecha de inicio:</span> {formatDateTime(event.start_date)}
                </div>
                <div>
                    <i className="fa-solid fa-calendar text-green me-2" />
                    <span className="fw-bold">Fecha de fin:</span> {formatDateTime(event.end_date)}
                </div>
                <div>
                    <i className="fa-solid fa-bars text-green me-2" />
                    <span className="fw-bold">Descripción:</span> {event.description}
                </div>
            </Popover.Body>
        </Popover>
    );

    return (
        <OverlayTrigger placement="left" overlay={popover}>
            <div
                className="card-event animate__animated animate__fadeIn"
                style={{
                    borderBottom: `10px solid ${event.label
                        ? event.label.color
                        : "rgba(51, 122, 255, 1)"} `,
                }}
                onDoubleClick={onEdit}
            >
                <div className="time">
                    <div className="fw-bold fs-7 text-center">
                        <i className="fa-solid fa-clock me-1 text-green"></i>
                        {formatTime(event.start_date ? event.start_date.split("T")[1] : "")}
                    </div>
                    <div className="notification-event">
                        <i className="fa-solid fa-bell me-1 text-"></i>
                        {event.notification_time}
                    </div>
                </div>
                <div className="main">
                    <div className="title">{event.title}</div>
                    <div className="actions">
                        <IconButton
                            icon="fa-solid fa-edit text-green"
                            title="Editar evento"
                            fontSize="14px"
                            onClick={onEdit}
                        />
                        <IconButton
                            icon="fa-solid fa-trash text-green"
                            title="Eliminar evento"
                            fontSize="14px"
                            onClick={onDelete}
                        />
                    </div>
                </div>
            </div>
        </OverlayTrigger>
    )
}
