import { useState } from "react";
import DynamicTable from "../../../TercerosCreate/components/DynamicTable";
import { SearchBarPeople } from "../../../TercerosCreate/components/SearchBarPeople";
import { CardActorLabel } from "./CardActorLabel";

import CardActorViewSignature from "../../../TercerosCreate/components/CardActorViewSignature";
import { useHandleActors } from "./useHandleActors";

import nonactors from "../../assets/nonactors.svg";
import { CustomPagination } from "../../../../app/components/CustomPagination";
import { SelectAndCreate } from "../../../TercerosCreate/components/SelectAndCreate";
import { HIDE } from "../../../../constant/constant";

const TabActors = ({ canEdit = true, setCurrentStep }) => {
  const { totalLabels, activeTypePeople, handleSelectedPerson, selectedPeople, dispatch } = useHandleActors();
  const [pagination, setPagination] = useState({ total_pages: 0, page: 0, page_size: 10 });

  return (
    <div className="process-tab  ">
      <div className="row responsive-sing">
        <div className="border1  col-12 col-lg-8 mb-3">
          <h5>
            <b className="font-lato text-green">Selecciona los actores</b>
          </h5>
          <p className="pactors">
            Este documento acepta <strong>{totalLabels} etiquetas</strong>. A
            continuación encontrarás los tipos de personas que fueron
            configurados para que hagas la búsqueda de los actores respectivos.
          </p>

          <div className="table-data">
            <div className="container-fluid">
              <h5 className="fw-bold">Filtros</h5>

              <SelectAndCreate activeTypePeople={activeTypePeople} showCreateButton={HIDE} showCreateLinkButton={HIDE} />
              <SearchBarPeople forParameterization={false} pagination={pagination} setPagination={setPagination} />
              <div className="row row-5" style={{ height: "20rem", overflowY: "auto" }}>
                <DynamicTable
                  actionName="Seleccionar"
                  forParameterization={false}
                  onPersonSelected={handleSelectedPerson}
                />
              </div>
              <CustomPagination
                pagination={pagination}
                setPagination={setPagination}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4 px-3 col-xs-5">
          <div className=" row border2">
            <h5 className="font-lato text-green fw-bold text-center pb-2">
              Seleccionados
            </h5>
            <div className="row actors-selected__list">
              <div className="col">
                {selectedPeople.length === 0 ? (
                  <div className="svgtable" role="alert">
                    <img src={nonactors} />
                    No hay actores seleccionados.
                  </div>
                ) : canEdit ? (
                  selectedPeople.map((person) => (
                    <CardActorLabel key={person?.people?.id} person={person} />
                  ))
                ) : (
                  selectedPeople.map((person) => (

                    <CardActorViewSignature
                      key={person.id}
                      actor={person}
                      label={person.label?.name}
                    />
                  ))
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <button
                type="button"
                className="btn btnactors btn-success rounded-3 d-block ms-auto mt-4 px-3 py-2"
                onClick={() => setCurrentStep((c) => c + 1)}
              >
                Siguiente paso <i className="fa-solid fa-caret-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabActors;
