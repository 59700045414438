import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { OverlayTrigger, Popover } from "react-bootstrap";
import CardSwitch from "../../../../app/components/CardSwitch";
import CardConfigActor from "./CardConfigActor";

import GeneralService from "../../../../services/GeneralService";
import { changeZapsignOptionPeople, reducerFormGenerateDocument, reducerFormSelectedPerson, setSelectedPerson, updateOnePersonFromPeople } from "../../../../reducers/documents/reducerGenerateDocument";
import { toast } from "../../../../utils/toast";
import { TOAST_DANGER, ID_NOT_DEFINED } from "../../../../constant/constant";
import { MessageError } from "../../../../utils/message";
import { setApplyDefault, setList } from "../../../../reducers/configuration/reducerZapsignConfiguration";
import { setList as setListTemplate } from "../../../../reducers/configuration/reducerTemplateEmail";
import { startModifyNeedsZapsign, startModifyUpdateTemplateEmail } from "../../../../reducers/documents/thunks";

const TabSending = ({ canEdit = true, setCurrentStep }) => {
  const { selectedPeople, selectedPerson, generateDocument } = useSelector(state => state.documentsAutoSlice);
  const { list: listTemplate } = useSelector(state => state.templateEmailSlice);
  const { applyDefault, list } = useSelector(state => state.zapsignConfigurationSlice);
  const dispatch = useDispatch();
  const refForm = useRef(null);

  const setData = (e) => {
    dispatch(
      reducerFormSelectedPerson({
        key: e.target.name,
        value: e.target.value,
      })
    );
  }

  const loadZapsignConfiguration = async () => {
    if (generateDocument.needs_zapsign) {
      const service = new GeneralService("/zapsign-configuration");
      const res = await service.getList();
      dispatch(setList({ value: res.configurations }));
    }
  }

  const loadTemplateEmail = async () => {
    if (!generateDocument.needs_another_template) return;
    const service = new GeneralService("template-email");
    const res = await service.getList(0);
    const { content = [] } = res;
    dispatch(setListTemplate(content));
  }

  const setDataSwitch = (e) => {
    dispatch(
      reducerFormSelectedPerson({
        key: e.target.name,
        value: e.target.checked,
      })
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!canEdit) {
      toast(dispatch, "Ya no puedes editar estos parámetros", TOAST_DANGER);
      return;
    }

    if (selectedPerson.id !== ID_NOT_DEFINED) {
      const service = new GeneralService("actors-generate-document");
      const res = await service.update(selectedPerson);
      if (!res.is_ok) {
        MessageError(res.message, service.status);
        return;
      }
    }
    proccessUpdate();
  }

  const proccessUpdate = () => {
    const actorsData = selectedPeople.map((item) => {
      if (item.people_id === selectedPerson.people_id) return selectedPerson;
      return item;
    });
    dispatch(updateOnePersonFromPeople({ value: actorsData }));
    toast(dispatch, "Cambios guardados en el actor");
  }

  const setZapsignConfiguration = () => {
    if (applyDefault) {
      dispatch(changeZapsignOptionPeople({ value: list }));
    }
  }

  const handleNeedsZapsign = async () => {
    dispatch(
      startModifyNeedsZapsign(
        generateDocument.id,
        !generateDocument.needs_zapsign
      )
    );
  }

  const handleNeedsAnotherTemplateEmail = async () => {
    const value = !generateDocument.needs_another_template;
    dispatch(reducerFormGenerateDocument({ key: "needs_another_template", value }));
    if (!value) {
      dispatch(startModifyUpdateTemplateEmail(generateDocument.id, ID_NOT_DEFINED));
    }
  }

  const handleUpdateTemplate = async (e) => {
    dispatch(startModifyUpdateTemplateEmail(generateDocument.id, parseInt(e.target.value)));
  }

  useEffect(() => {
    if (selectedPeople.length > 0) {
      dispatch(setSelectedPerson({ value: selectedPeople[0] }));
    }
  }, []);

  useEffect(() => {
    loadZapsignConfiguration();
  }, [generateDocument.needs_zapsign]);

  useEffect(() => {
    setZapsignConfiguration();
  }, [applyDefault, list]);

  useEffect(() => {
    loadTemplateEmail();
  }, [generateDocument.needs_another_template]);

  return (
    <div className="process-tab">
      <div className="row responsive-sing">
        <div className="border3 col-12 col-lg-7 mb-3">
          <h5 className="text-green"><b>Gestiona las firmas</b></h5>
          <p className="pactors">
            A continuación puedes ver los actores que seleccionaste en el paso
            anterior, y puedes elegir si requiere firma electrónica, esto aplica para todos los actores.
          </p>

          <div className="row">
            <div className="col-12 col-lg-5">
              <h5 className="text-green">Plantilla de correo</h5>
              <CardSwitch
                name="needs_another_template"
                icon="fa-solid fa-signature"
                title="¿Deseas usar otra plantilla de correo?"
                checked={generateDocument.needs_another_template}
                disabled={!canEdit}
                onChange={handleNeedsAnotherTemplateEmail}
              />
            </div>
            <div className="col-12 col-lg-5">
              {
                generateDocument.needs_another_template
                &&
                <>
                  <label>Plantilla de correo<b><i className="text-red">*</i></b></label>
                  <select
                    className="form-select input-borderless green-input"
                    name="template_email_id"
                    value={generateDocument.template_email_id}
                    onChange={handleUpdateTemplate}
                  >
                    <option value="">Plantilla por defecto</option>
                    {
                      listTemplate.map(item => (<option value={item.id} key={item.id}>
                        {item.name}
                      </option>))
                    }
                  </select>
                </>
              }
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12 col-lg-5">
              <div className="firma-electronica pactors">
                <h5 className="text-green">Firma electrónica</h5>
                <CardSwitch
                  name="needs_zapsign"
                  icon="fa-solid fa-signature"
                  title="¿Necesitas firma electrónica?"
                  checked={generateDocument.needs_zapsign}
                  disabled={!canEdit}
                  onChange={handleNeedsZapsign}
                />
                {
                  generateDocument.needs_zapsign
                  &&
                  <CardSwitch
                    name="apply_default"
                    icon="fa-solid fa-sliders"
                    title="Configuración por defecto"
                    checked={applyDefault}
                    onChange={() => dispatch(setApplyDefault({ value: !applyDefault }))}
                  />
                }
              </div>
            </div>
          </div>
          <div className="row mt-4">
            {generateDocument.needs_zapsign &&
              <>
                <div className="col-12 col-lg-6">
                  <h5 className="text-green">Formas de verificación</h5>
                  <CardSwitch
                    icon="fa-solid fa-camera text-green"
                    name="require_selfie_photo"
                    title="Selfie"
                    checked={selectedPerson.require_selfie_photo}
                    disabled={!canEdit}
                    onChange={setDataSwitch}
                  />
                  <CardSwitch
                    icon="fa-solid fa-address-card text-green"
                    name="require_document_photo"
                    title="Documento de identidad"
                    checked={selectedPerson.require_document_photo}
                    disabled={!canEdit}
                    onChange={setDataSwitch}
                  />
                </div>
                <div className="col-12 col-lg-6">
                  <h5 className="text-green">Envío automático</h5>
                  <CardSwitch
                    icon="fa-brands fa-square-whatsapp text-green"
                    name="automatic_whatsapp"
                    title="WhatsApp"
                    checked={selectedPerson.automatic_whatsapp}
                    disabled={!canEdit}
                    onChange={setDataSwitch}
                  />
                  <CardSwitch
                    icon="fa-solid fa-envelope text-green"
                    name="automatic_mailing"
                    title="Correo electrónico"
                    checked={selectedPerson.automatic_mailing}
                    disabled={!canEdit}
                    onChange={setDataSwitch}
                  />
                </div>
              </>
            }
          </div>
        </div>

        <div className=" col-12 col-lg-5 px-3  col-xs-5">
          <div className="row border4">
            <h5>
              <b className="bactors text-green">Resumen</b>
              <OverlayTrigger
                placement="left"
                overlay={
                  <Popover id={`popover-positioned`}>
                    <Popover.Header as="h3" className="bg-green text-white text-center">Gestión de Actores</Popover.Header>
                    <Popover.Body>
                      <p>
                        En esta sección puedes seleccionar a los actores que
                        participarán en el documento. También podrás modificar
                        sus datos, como el <strong>nombre</strong>, el{" "}
                        <strong>WhatsApp</strong> y el{" "}
                        <strong>correo electrónico</strong>.
                      </p>
                    </Popover.Body>
                  </Popover>
                }
              >
                <i className="fa-solid fa-circle-question ms-2 text-green" role="button"></i>
              </OverlayTrigger>
            </h5>
            {Object.keys(selectedPerson).length > 0 && (
              <form ref={refForm} onSubmit={handleSubmit} className="pb-5">
                <div className="col personassing">
                  {selectedPeople.map((person) => (
                    <CardConfigActor
                      key={person.people_id}
                      person={person}
                      active={selectedPerson.people_id === person.people_id}
                      canEdit={canEdit}
                      refForm={refForm}
                    />
                  ))}
                </div>
                <h5 className="font-lato fw-bold text-green  pb-2 pt-4">
                  <i className="fa-regular fa-address-book me-2"></i>
                  Datos de contacto
                </h5>

                <div className="row mt-2">
                  <div className="col-xxl-3 col-xl-12 col-12">
                    Nombre
                    <b>
                      <i className="text-red">*</i>
                    </b>
                  </div>
                  <div className="col-xxl-9 col-xl-12 col-md-12 col-12">
                    <input
                      type="text"
                      className="form-control green-input inputfirmas"
                      name="name"
                      placeholder="Digita nombre(s)"
                      value={selectedPerson.name}
                      minLength="3"
                      maxLength="25"
                      onChange={setData}
                      readOnly={!canEdit}
                      required
                    />
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-xxl-3 col-xl-12 col-md-12 col-12">
                    WhatsApp
                  </div>
                  <div className="col-xxl-9 col-xl-12 col-md-12 col-12">
                    <input
                      type="text"
                      className="form-control green-input inputfirmas"
                      name="phone"
                      placeholder="Digita número telefónico"
                      value={selectedPerson.phone}
                      minLength="3"
                      maxLength="25"
                      onChange={setData}
                      readOnly={!canEdit}
                    />
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-xxl-3 col-xl-12 col-12">
                    Correo
                  </div>
                  <div className="col-xxl-9 col-xl-12 col-md-12 col-12">
                    <input
                      type="text"
                      className="form-control green-input inputfirmas"
                      name="email"
                      placeholder="Digita correo electrónico"
                      value={selectedPerson.email}
                      minLength="3"
                      maxLength="100"
                      onChange={setData}
                      readOnly={!canEdit}
                    />
                  </div>
                </div>
              </form>
            )}
          </div>
          <div className="row">
            <div className="col">
              <button
                type="button"
                className="btn btnactors btn-success rounded-3 d-block ms-auto mt-4 px-3 py-2"
                onClick={() => {
                  if (refForm.current.checkValidity())
                    setCurrentStep((c) => c + 1);
                  else refForm.current.reportValidity();
                }}
              >
                Siguiente paso <i className="fa-solid fa-caret-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabSending;