import { NavDropdown } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

export const ItemDropdownNavigation = ({ path = "", icon = "", title = "" }) => {
    const location = useLocation();

    const isActive = location.pathname === path;

    return (
        <NavDropdown.Item as={Link} to={path} className='item__menu item__dropdown mt-2'>
            <i className={`${icon} fs-5 icon__menu ms-2`}></i> {title}
        </NavDropdown.Item>
    );
}
