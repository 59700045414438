import { useState, useRef, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import es from "@fullcalendar/core/locales/es";
import { useDispatch } from "react-redux";

import { setFilters } from "../../../reducers/calendar/reducerCalendar";

export const CustomCalendar = ({ reFilter, eventosDB, onDateSelect }) => {
  const refCalendar = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      refCalendar.current.getApi().updateSize();
    });

    if (reFilter.current) {
      resizeObserver.observe(reFilter.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, []);


  const handleDatesSet = (info) => {
    info.start = info.start.toISOString().slice(0, 16);
    info.end = info.end.toISOString().slice(0, 16);
    dispatch(setFilters({ key: "label_id", value: 0 }));
    dispatch(setFilters({ key: "start_date", value: info.start }));
    dispatch(setFilters({ key: "end_date", value: info.end }));
  };

  return (
      <FullCalendar
        ref={refCalendar}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        locale={es}
        weekends={true}
        timeZone='local'
        headerToolbar={{
          left: "prev,next",
          center: "title",
          right: "",
        }}
        datesSet={handleDatesSet}
        events={eventosDB || []}
        dateClick={onDateSelect}
        dayCellClassNames="custom-day-cell"
      />
  );
}
