
export const ItemLabelFilter = ({ label, isActive = false, onClick }) => {
    return (
        <div
            key={label.id}
            className="item__label-calendar"
            style={{
                color: label.label_color.color_font,
                backgroundColor: label.label_color.color,
            }}
            onClick={onClick}
        >
            <i className="fa-solid fa-tag me-2" style={{ color: label.label_color.color_font }}></i>
            {label.name}
            {
                isActive
                ?
                <i className="fa-solid fa-circle-dot"></i>
                :
                <i className="fa-regular fa-circle-dot"></i>
            }
        </div>
    )
}
