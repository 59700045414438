import { useDispatch, useSelector } from "react-redux";
import Close from "../../../assets/close.svg";
import MenuButton from "../../../assets/menu.svg";
import LegalDesign from "../../../assets/legal-design.svg";
import {
  setIsExpanded,
  setExpandDocument,
  setExpandDocumentToggle,
} from "../../../reducers/main/menuReducer";
import ItemNavigation from "./ItemNavigation";
import DrodownNavigation from "./DrodownNavigation";
import { startLogout } from "../../../reducers/auth/thunks";
import { Button, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { ItemDropdownNavigation } from "./ItemDropdownNavigation";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useResize } from "../../hooks/useResize";

const Menu = () => {
  const { isExpanded, expandDocument } = useSelector(state => state.menuSlice);
  const { canActionRoles } = useSelector((state) => state.loginSlice);
  const { company } = useSelector((state) => state.businessSlice);
  const dispatch = useDispatch();
  const { windowSize } = useResize();
  const isMobile = windowSize.width < 992;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const openMenu = () => {
    if (isExpanded) {
      dispatch(setExpandDocument({ value: false }));
    }
    dispatch(setIsExpanded());
  };

  const openMenuAndOpcionDocument = () => {
    if (!isExpanded) {
      dispatch(setIsExpanded());
      dispatch(setExpandDocumentToggle());
      return;
    }
    dispatch(setExpandDocumentToggle());
  }

  const logo = (
    <>
      <img src={LegalDesign} className="img-logo" alt="" />
      <span className="fs-4 font-lilita-one">SORTOM</span>
    </>
  );

  const contentNavigation = (
    <>
      <ItemNavigation
        icon="fa-solid fa-gauge-high"
        path="/"
        title="Dashboard"
        isExpanded={isExpanded}
        isMobile={isMobile}
      />
      <ItemNavigation
        icon="fa-solid fa-message"
        path="/chat"
        title="Chat"
        isExpanded={isExpanded}
        isMobile={isMobile}
      />
      <ItemNavigation
        icon="fa-solid fa-user"
        path="/users/list"
        title="Usuarios"
        isExpanded={isExpanded}
        isMobile={isMobile}
      />
      {company.is_system_admin && (
        <ItemNavigation
          icon="fa-solid fa-landmark"
          path="/business/list"
          title="Empresas"
          isExpanded={isExpanded}
          isMobile={isMobile}
        />
      )}
      {canActionRoles && (
        <ItemNavigation
          icon="fa-solid fa-ranking-star"
          path="/roles/list"
          title="Roles"
          isExpanded={isExpanded}
          isMobile={isMobile}
        />
      )}

      <DrodownNavigation
        id="dropdown-people"
        icon="fa-solid fa-person"
        title="Personas"
        isExpanded={isExpanded}
        isMobile={isMobile}
      >
        <ItemDropdownNavigation
          icon="fa-solid fa-list-check"
          path="/typepeople/list"
          title="Parametrizar"
          isExpanded={isExpanded}
          isMobile={isMobile}
        />
        <ItemDropdownNavigation
          icon="fa-solid fa-person-circle-plus"
          path="/people/list"
          title="Listar"
          isExpanded={isExpanded}
          isMobile={isMobile}
        />
      </DrodownNavigation>

      <DrodownNavigation
        id="dropdown-documents"
        icon="fa-solid fa-file-lines"
        title="Documentos"
        isExpanded={isExpanded}
        isMobile={isMobile}
      >
        <ItemDropdownNavigation
          icon="fa-solid fa-list-check"
          path="/documents-auto/list"
          title="Parametrizar"
          isExpanded={isExpanded}
          isMobile={isMobile}
        />
        <ItemDropdownNavigation
          icon="fa-solid fa-signature"
          path="/documents-auto/signatures"
          title="Firmas"
          isExpanded={isExpanded}
          isMobile={isMobile}
        />
        <ItemDropdownNavigation
          icon="fa-solid fa-bars-progress"
          path="/documents-auto/in-progress"
          title="En progreso"
          isExpanded={isExpanded}
          isMobile={isMobile}
        />
      </DrodownNavigation>

      <DrodownNavigation
        id="dropdown-forms"
        icon="fa-solid fa-file-invoice"
        title="Formularios"
        isExpanded={isExpanded}
        isMobile={isMobile}
      >
        <ItemDropdownNavigation
          icon="fa-solid fa-list-check"
          path="/form-parameterization/list"
          title="Parametrizar"
          isExpanded={isExpanded}
          isMobile={isMobile}
        />
        <ItemDropdownNavigation
          icon="fa-solid fa-signature"
          path="/process/virtual-notary/list"
          title="Firmas"
          isExpanded={isExpanded}
          isMobile={isMobile}
        />
      </DrodownNavigation>

      <DrodownNavigation
        id="dropdown-expedients"
        icon="fa-solid fa-folder-closed"
        title="Expedientes"
        isExpanded={isExpanded}
        isMobile={isMobile}
      >
        <ItemDropdownNavigation
          icon="fa-solid fa-list-check"
          path="/type-process/list"
          title="Tipos proceso"
          isExpanded={isExpanded}
          isMobile={isMobile}
        />
        <ItemDropdownNavigation
          icon="fa-solid fa-folder"
          path="/expedient/list"
          title="Listar"
          isExpanded={isExpanded}
          isMobile={isMobile}
        />
      </DrodownNavigation>

      <ItemNavigation
        icon="fa-solid fa-calendar-days"
        path="/calendar"
        title="Calendario"
        isExpanded={isExpanded}
        isMobile={isMobile}
      />
      <ItemNavigation
        icon="fa-solid fa-file-import"
        path="/importers"
        title="Importaciones"
        isExpanded={isExpanded}
        isMobile={isMobile}
      />

      <hr className="text-white my-3" />

      <ItemNavigation
        icon="fa-solid fa-gear"
        path="/configurations"
        title="Configuración"
        isExpanded={isExpanded}
        isMobile={isMobile}
      />

      <div
        role="button"
        className="d-block p-2 text-white mt-1"
        onClick={() => dispatch(startLogout())}
      >
        {
          isMobile
            ?
            <>
              <i className="fa-solid fa-right-from-bracket fs-5 ms-2 me-2"></i>Salir
            </>
            :
            isExpanded
              ?
              <>
                <i className="fa-solid fa-right-from-bracket fs-5 ms-3 me-2"></i>Salir
              </>
              : <i className="fa-solid fa-right-from-bracket fs-5 mx-1"></i>
        }
      </div>
    </>
  );

  return (
    <>
      {
        !show
        &&
        <button onClick={handleShow} className="btn btn-success d-lg-none position-fixed" style={{ top: 7, left: 7, zIndex: 1050 }}>
          <i className="fa-solid fa-bars text-white"></i>
        </button>
      }
      <div className="sidebar-menu__layout">
        <div className="button-menu" title="Menú" onClick={() => openMenu()}>
          {!isExpanded ? (
            <img
              className="animate__animated animate__rotateIn animate__slow"
              src={MenuButton}
              alt=""
            />
          ) : (
            <img
              className="animate__animated animate__rotateIn animate__slow"
              src={Close}
              alt=""
            />
          )}
        </div>
        <hr className="text-white my-2" />

        <Navbar.Brand as={Link} to="/" className="d-flex flex-row justify-content-center mb-4">
          {isExpanded && logo}
        </Navbar.Brand>

        {contentNavigation}

      </div>

      <Offcanvas show={show} onHide={handleClose} placement="start">
        <Offcanvas.Header className="bg-green text-white" closeButton closeVariant="white">
          <Offcanvas.Title className="d-flex flex-row justify-content-center w-100">{logo}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="bg-green text-white px-0">
          <Nav className="flex-column text-white">
            {contentNavigation}
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Menu;
