import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import IconButton from "../../app/components/IconButton";
import { Dropdowncustome, ModalEvent, ModalLabel } from "./components/index";
import { LabelCalendar } from "./components/LabelCalendar";
import { ItemLabelFilter } from "./components/ItemLabelFilter";
import { ItemEvent } from "./components/ItemEvent";
import { CustomCalendar } from "./components/CustomCalendar";

import GeneralService from "../../services/GeneralService";
import { setListLabels, setEvents, setEventsday, setListUser, eventUpdate, setFilters, setEvent, setCleanEvent, removeFromListLabels, deleteFromListEvents } from "../../reducers/calendar/reducerCalendar";
import { HIDE, SHOW } from "../../constant/constant";
import confirmDelete from "../../utils/confirmDelete";
import { MessageError, toast } from "../../utils";
import "./styles/calendarstyle.css";

const Calendar = () => {
  const { labels, events, eventsday, filters } = useSelector(state => state.calendarslice);
  const [showModalLabel, setShowModalLabel] = useState(HIDE);
  const [showModalEvent, setShowModalEvent] = useState(HIDE);
  const dispatch = useDispatch();
  const reFilter = useRef(null);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split("T")[0]);

  useEffect(() => {
    getEvents();
    getLabels();
    getEventsday(selectedDate);
    getUser();
  }, [filters]);

  const getEvents = async () => {
    const service = new GeneralService("events");

    const res = await service.getList(1000, filters);
    const { list = [] } = res;
    dispatch(setEvents(list));
  };

  const isSameDay = (dateEvent, convertedSelectedDate) => {
    return (
      dateEvent.getFullYear() === convertedSelectedDate.getFullYear() &&
      dateEvent.getMonth() === convertedSelectedDate.getMonth() &&
      dateEvent.getDate() === convertedSelectedDate.getDate()
    );
  }

  const getEventsday = async (date) => {
    const modifiedDate = `${date}T00:00`;
    const convertedSelectedDate = new Date(modifiedDate);

    const eventDays = events.filter((event) => isSameDay(new Date(event.start_date), convertedSelectedDate));
    dispatch(setEventsday({ value: eventDays }));
  }

  const getLabels = async () => {
    const service = new GeneralService("labels");
    const { labels = [] } = await service.getList(1000);
    dispatch(setListLabels(labels));
  }

  const transformEvents = (events) => {
    return events.map((event) => ({
      title: event.title,
      start: event.start_date,
      end: event.end_date || event.start_date,
      textColor: event.label ? event.label.color_font : null,
      backgroundColor: event.label ? event.label.color : null,
    }));
  }

  const transformedEvents = transformEvents(events);

  const getUser = async () => {
    const service = new GeneralService("user");
    const { profile = [] } = await service.getList(1000);
    dispatch(setListUser({ value: profile }));
  }

  const handleSelectLabelFilter = (itemid) => {
    dispatch(setFilters({ key: "label_id", value: itemid }));
  }

  const handleDelete = async (id) => {
    const service = new GeneralService(`labels`);
    const res = await service.delete(id);
    if (res.is_ok) {
      toast(dispatch, res.message);
      dispatch(removeFromListLabels(id));
      return;
    }
    MessageError(res.message);
  }

  const handleDateSelect = (arg) => {
    setSelectedDate(arg.dateStr);
    dispatch(setEvent({ key: "start_date", value: arg.dateStr + "T00:00" }));
    getEventsday(arg.dateStr);
  }

  const handleDeleteEvent = async (item) => {
    const service = new GeneralService(`events`);
    const res = await service.delete(item.id);
    if (res.is_ok) {
      toast(dispatch, res.message);
      dispatch(deleteFromListEvents(item.id));
      return;
    }
    MessageError(res.message);
  }

  const handleEditEvent = (item) => {
    dispatch(eventUpdate({
      ...item,
      labels_id: item.LabelShort ? item.LabelShort.map(label => label.id) : undefined
    }));
    setShowModalEvent(SHOW);
  }

  const handleCreateEvent = () => {
    dispatch(setCleanEvent());
    setShowModalEvent(SHOW)
  }

  return (
    <div className="container-fluid max-height-overflow-y">
      <div className="row">
        <div className="col-12 col-lg-11">
          <div className="container-parent">
            <div className="divcat2">
              {
                labels.map((label) => <LabelCalendar
                  key={label.id}
                  label={label}
                  onDelete={async () => await confirmDelete(() => handleDelete(label.id))}
                />)
              }
            </div>
          </div>

        </div>
        <div className="col-12 col-lg-1">
          <div className="d-flex flex-row align-items-center justify-content-center">
            <Dropdowncustome
              label={
                <a className="a">
                  <i className="fa-solid fa-filter icon"></i>
                </a>
              }
              position="bottom-right"
              width={250}
            >
              <div className="dropdown-locale">
                <p className="fw-bold">Filtros</p>
                {
                  labels.map((label, index) => <ItemLabelFilter
                    key={index}
                    label={label}
                    onClick={() => handleSelectLabelFilter(label.id)}
                    isActive={filters.label_id === label.id}
                  />)
                }
              </div>
            </Dropdowncustome>

            <IconButton
              icon="fa-solid fa-circle-plus text-green"
              title="Añadir etiqueta"
              onClick={() => setShowModalLabel(SHOW)}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-9">
          <CustomCalendar
            reFilter={reFilter}
            eventosDB={transformedEvents}
            onDateSelect={handleDateSelect}
          >
            <div className="divcat2">
              {
                labels.map((label) => <LabelCalendar
                  key={label.id}
                  label={label}
                  showAction={HIDE}
                />)
              }
            </div>
          </CustomCalendar>
        </div>
        <div className="col-lg-3">
          <div className="sidebar-selected-events">
            <div className="divcalendar-2-header">
              <span className="fw-bold">{selectedDate}</span>
              <IconButton
                icon="fa-solid fa-circle-plus text-green"
                title="Añadir evento"
                onClick={handleCreateEvent}
              />
            </div>
            <div className="list-events">
              {
                eventsday.length === 0
                  ?
                  <div className="alert bg-light-green text-center mt-2" role="alert">
                    <i className="fa-solid fa-calendar-minus text-green me-1"></i> Sin eventos
                  </div>
                  :
                  eventsday.map((event, index) => <ItemEvent
                    key={index}
                    event={event}
                    onDelete={() => confirmDelete(() => handleDeleteEvent(event))}
                    onEdit={() => handleEditEvent(event)}
                  />)
              }
            </div>
          </div>
        </div>
      </div>

      <ModalLabel show={showModalLabel} setShow={setShowModalLabel} />
      <ModalEvent
        show={showModalEvent}
        setShow={setShowModalEvent}
        selectDate={selectedDate}
      />
    </div>
  );
};

export default Calendar;
