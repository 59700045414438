import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";
import { useParams, useLocation, useHistory } from "react-router-dom";
import queryString from 'query-string';

import BreadcrumbCustom from "../../../app/components/BreadcrumCustom";
import { ContentParts } from "./components/ContentParts";
import { ContentMovements } from "./components/ContentMovements";
import { ContentStorage } from "./components/ContentStorage";
import { FormExpedient } from "./components/FormExpedient";
import { FolderIcon } from "./components/FolderIcon";

import GeneralService from "../../../services/GeneralService";
import { HTTP_OK, ID_NOT_DEFINED } from "../../../constant/constant";
import { reducerForm, setSelected } from "../../../reducers/expedient/reducerExpedient";
import { cutText } from "../../../utils";
import { setList } from "../../../reducers/configuration/reducerColors";
import { setList as setListLabels } from "../../../reducers/configuration/reducerLabelGlobal";
import { setList as setListGroups } from "../../../reducers/configuration/reducerExpedientGroups";
import './styles/tabs.css';
import { setUsers } from "../../../reducers/user/reducerUsers";

export const ModifyExpedientView = () => {
    const { selectedExpedient } = useSelector(state => state.expedientSlice);
    const { list } = useSelector(state => state.colorsSlice);
    const { list: listUsers } = useSelector(state => state.userSlice);
    let { id = 0 } = useParams();
    const { search } = useLocation();
    const { tab = 'definition' } = queryString.parse(search);
    const [key, setKey] = useState(tab);
    const dispatch = useDispatch();
    const history = useHistory();

    const loadExpedient = async () => {
        if (id === ID_NOT_DEFINED) return;

        const service = new GeneralService("expedient/" + id);
        const res = await service.getRequest();
        if (service.status === HTTP_OK) {
            dispatch(setSelected(res));
        }
    }

    const loadColors = async () => {
        const service = new GeneralService("colors");
        const res = await service.getList({ page_size: 1000 });
        const { colors = [] } = res;
        dispatch(setList(colors));
    }

    const loadUsers = async () => {
        const service = new GeneralService("user");
        const res = await service.getList(0, { page: 0, page_size: 1000 });
        const { profile = [] } = res;
        dispatch(setUsers(profile));
    }

    const loadLabels = async () => {
        const service = new GeneralService("label-global");
        const res = await service.getList(0, { page_size: 1000 });
        const { labels = [] } = res;
        dispatch(setListLabels(labels));
    }

    const loadGroups = async () => {
        const service = new GeneralService("expedient-groups");
        const res = await service.getList();
        const { groups = [] } = res;
        dispatch(setListGroups(groups));
    }

    const onSelect = (k) => {
        setKey(k);
        history.push("?tab=" + k);
    }

    const verifyColorsAndAssingAutomatically = () => {
        if (selectedExpedient.id !== ID_NOT_DEFINED || id !== ID_NOT_DEFINED) return;
        dispatch(reducerForm({ key: "colors_id", value: list[0]?.id }));
    }

    const verifyUsersAndAssignAutomatically = () => {
        if (selectedExpedient.id !== ID_NOT_DEFINED || id !== ID_NOT_DEFINED) return;
        dispatch(reducerForm({ key: "user_id", value: listUsers[0]?.id }));
    }

    useEffect(() => {
        verifyColorsAndAssingAutomatically();
    }, [list, selectedExpedient.id, id]);

    useEffect(() => {
        verifyUsersAndAssignAutomatically();
    }, [listUsers, selectedExpedient.id, id]);

    useEffect(() => {
        loadExpedient();
    }, [id]);

    useEffect(() => {
        loadColors();
        loadLabels();
        loadGroups();
        loadUsers();
    }, []);

    return (
        <>
            <div className="container-fluid pb-4 max-height-overflow-y bg-white">
                <div className="row">
                    <div className="col">
                        <BreadcrumbCustom
                            title="Expedientes"
                            routes={[
                                { name: "Expedientes", link: "/expedient/list" },
                                { name: selectedExpedient.name ? cutText(selectedExpedient.name, 50) : "Crear expediente", link: "/expedient/list" },
                            ]}
                            activeRoute={id === ID_NOT_DEFINED ? "Crear" : "Actualizar"}
                        />
                    </div>
                </div>

                <div className="row mt-1">
                    <div className="col-12">

                        <Tabs
                            id="tab-expedients"
                            className="mb-3 custom-tabs"
                            activeKey={key}
                            onSelect={onSelect}
                            fill
                        >
                            <Tab
                                eventKey="definition"
                                className="px-2"
                                title={
                                    <>
                                        <FolderIcon
                                            height={17}
                                            width={22}
                                            color={
                                                id === ID_NOT_DEFINED && selectedExpedient.colors_id === ID_NOT_DEFINED
                                                    ?
                                                    "4EA07A"
                                                    :
                                                    list.find(itemColor => itemColor.id === selectedExpedient.colors_id)?.color
                                            }
                                        />
                                        <h6 className="d-inline-block ms-2 text-black font-rubik">Definición</h6>
                                    </>
                                }
                            >
                                <FormExpedient setKey={setKey} />
                            </Tab>
                            <Tab
                                eventKey="parts"
                                className="px-2"
                                title={
                                    <>
                                        <i className="fa-solid fa-user-group text-green fs-5"></i>
                                        <h6 className="d-inline-block ms-2 text-black font-rubik">Partes</h6>
                                    </>
                                }
                                disabled={selectedExpedient.id === ID_NOT_DEFINED}
                            >
                                <ContentParts />
                            </Tab>
                            <Tab
                                eventKey="movements"
                                className="px-2"
                                title={
                                    <>
                                        <i className="fa-solid fa-arrows-to-dot text-yellow fs-5"></i>
                                        <h6 className="d-inline-block ms-2 text-black font-rubik">Movimientos</h6>
                                    </>
                                }
                                disabled={selectedExpedient.id === ID_NOT_DEFINED}
                            >
                                <ContentMovements />
                            </Tab>
                            <Tab
                                eventKey="storage"
                                className="px-2"
                                title={
                                    <>
                                        <i className="fa-solid fa-chart-pie text-purple fs-5"></i>
                                        <h6 className="d-inline-block ms-2 text-black font-rubik">Almacenamiento</h6>
                                    </>
                                }
                                disabled={selectedExpedient.id === ID_NOT_DEFINED}
                            >
                                <ContentStorage />
                            </Tab>
                        </Tabs>

                    </div>
                </div>

            </div >
        </>
    )
}
