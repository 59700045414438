import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import BreadcrumbCustom from "../../app/components/BreadcrumCustom";
import CardSwitch from "../../app/components/CardSwitch";
import { Step1 } from "./components/Step1";
import { Step2 } from "./components/Step2";
import { Step3 } from "./components/Step3";
import { ExcelErrorTable } from "./components/ExcelErrorTable";

import GeneralService from "../../services/GeneralService";
import { MessageError, MessageSuccess, toast } from "../../utils";
import { COLLECTION_DOCUMENTS, COLLECTION_PEOPLE, HIDE, HTTP_OK, SHOW, TOAST_DANGER } from "../../constant/constant";
import { setListParametrizeDocuments } from "../../reducers/documents/reducerGenerateDocument";
import { insertFromImporter, setListMapping, setListMappingActors } from "../../reducers/importer/reducerImporter";
import { hideLoading, showLoading } from "../../reducers/main/toastProgressSlice";
import { validateExcelData, validateRequiredFields } from "./utils/utils";

export const CreateImportView = () => {
    const { listMapping, listMappingActors } = useSelector(state => state.reducerImporterSlice);
    const { list } = useSelector(state => state.typepeopleSlice);
    const refContainer = useRef();

    const [state, setState] = useState({
        fileData: [],
        document: "",
        fileName: "",
        collection: "",
        typePeople: "",
        typeDocument: "",
        typeProcess: "",
        showApplyConfiguration: { show: HIDE, data: {} },
        applyConfiguration: false,
        generateExpedients: false,
        saveAsDefault: false,
        worksheet: [],
        errorsWorksheet: [],
    })
    const dispatch = useDispatch();
    const history = useHistory();

    const loadTemplates = async () => {
        const service = new GeneralService("documents-auto");
        const res = await service.getList(1000);
        const { documents = [] } = res;
        dispatch(setListParametrizeDocuments({ value: documents }));
    }

    const handleCreateImport = async () => {
        const itemImport = {
            id_default: state.showApplyConfiguration.data?.id,
            data: {
                collection: state.collection,
                type_peoples_id: state.typePeople ? parseInt(state.typePeople) : undefined,
                documents_auto_id: state.typeDocument ? parseInt(state.typeDocument) : undefined,
                mappings: [...listMapping, ...listMappingActors],
                file: `${state.document}--EXTENSION--xlsx`,
                file_name: state.fileName,
                save_as_default: state.saveAsDefault,
                generate_expedients: state.generateExpedients,
                type_process_id: state.typeProcess ? parseInt(state.typeProcess) : undefined,
            }
        }

        if (!state.showApplyConfiguration.data?.id && state.collection === COLLECTION_PEOPLE) {
            const typePeople = list.find(typeP => typeP.id === parseInt(state.typePeople));
            if (!validateRequiredFields(typePeople, listMapping)) {
                return MessageError("No has configurado correctamente los campos. Revisa los campos que son requeridos.");
            }

            const errors = validateExcelData(state.worksheet, listMapping, typePeople);
            setState({ ...state, errorsWorksheet: errors });
            if (errors.length > 0) {
                toast(dispatch, "Errores en tu Base de Datos", TOAST_DANGER);
                return;
            }
        }

        const service = new GeneralService("data-import");
        dispatch(showLoading("Importación en progreso"));
        history.push("/importers");
        service.post(itemImport).then(res => {
            dispatch(hideLoading());
            if (res.is_ok) {
                dispatch(insertFromImporter(res.content));
                MessageSuccess(res.message);
                return;
            }
            MessageError(res.message);
        });
    }

    const loadDefault = async () => {
        if (!(state.collection && (state.typePeople || state.typeDocument))) return;
        const service = new GeneralService("data-import/load-default")
        const res = await service.getRequest({
            collection: state.collection,
            collection_id: state.collection === COLLECTION_PEOPLE ? state.typePeople : state.typeDocument,
        });
        if (service.status === HTTP_OK) {
            setState({ ...state, showApplyConfiguration: { show: SHOW, data: res.content } });
            return;
        }
        setState({ ...state, showApplyConfiguration: { show: HIDE, data: {} } });
    }

    useEffect(() => {
        loadTemplates();

        return () => {
            dispatch(setListMapping([]));
            dispatch(setListMappingActors([]));
        }
    }, []);

    useEffect(() => {
        loadDefault();
    }, [state.typeDocument, state.typePeople]);

    useEffect(() => {
        if (state.errorsWorksheet.length > 0 && refContainer.current) {
            setTimeout(() => {
                const divObject = refContainer.current;
                divObject.scrollTop = divObject.scrollHeight;
            }, 100);
        }
    }, [state.errorsWorksheet]);

    return (
        <div ref={refContainer} className="container-fluid max-height-overflow-y min-height-overflow-y">
            <div className="row">
                <div className="col">
                    <BreadcrumbCustom
                        title="Importadores"
                        activeRoute="Crear importación"
                    />
                </div>
            </div>
            <p>
                En esta sección podrás importar documentos, generarlos a partir de la base de datos y ver un listado de todas las
                importaciones realizadas.
            </p>

            <Step1 state={state} setState={setState} />

            {
                state.showApplyConfiguration.show
                &&
                <div className="row p-3 bg-light-green my-2">
                    <div className="col-12 col-lg-6">
                        Se ha encontrado una configuración para este {state.collection === COLLECTION_DOCUMENTS ? "plantilla" : "tipo de persona"}.
                        Aplícala solo si tu excel conserva la misma estructura
                    </div>
                    <div className="col-12 col-lg-6">
                        <CardSwitch
                            icon="fa-solid fa-camera text-green"
                            name="require_selfie_photo"
                            title="¿Deseas aplicar la configuración?"
                            labelClassName="fw-bold"
                            checked={state.applyConfiguration}
                            onChange={() => setState({ ...state, applyConfiguration: !state.applyConfiguration })}
                        />
                    </div>
                </div>
            }

            {
                !state.applyConfiguration
                &&
                <>
                    {
                        (state.typePeople || state.typeDocument) &&
                        <>
                            <hr />
                            <Step2 state={state} />
                        </>
                    }

                    {
                        (listMapping.length > 0 || listMappingActors.length > 0) &&
                        <>
                            <hr />
                            <Step3 state={state} setState={setState} />
                        </>
                    }
                </>
            }

            {state.errorsWorksheet.length > 0 && <ExcelErrorTable errors={state.errorsWorksheet} />}

            <div className="row my-4">
                <div className="col">
                    <button title="Crear importación" className="btn btn-success mt-3 d-block mx-auto" type="button" onClick={handleCreateImport}>
                        <i className="fa-solid fa-file-import text-white me-2" />
                        Crear
                    </button>
                </div>
            </div>
        </div>
    );
}
