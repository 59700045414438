
export const ExcelErrorTable = ({ errors }) => {
    return (
        <div className="container-full mt-3">
            <h5 className="fw-bold mb-2"><i className="fa-solid fa-triangle-exclamation text-orange"></i> Errores encontrados</h5>
            <p className="mb-4">Hemos encontrado algunos errores en tu base de datos, corrígelos para poder realizar la importación.</p>

            <div className="divxscroll rounded-3 clip-hide">
                <table className="table table-responsive table-header-custom animate__animated animate__fadeIn table-striped">
                    <thead>
                        <tr className="bg-gray-200">
                            <th className="border px-4 py-2">Celda</th>
                            <th className="border px-4 py-2">Columna</th>
                            <th className="border px-4 py-2">Fila</th>
                            <th className="border px-4 py-2">Nombre de Columna</th>
                            <th className="border px-4 py-2">Error</th>
                            <th className="border px-4 py-2">Datos de la Fila</th>
                        </tr>
                    </thead>
                    <tbody>
                        {errors.map((error, index) => (
                            <tr key={index} className="border">
                                <td className="border px-4 py-2">{error.cellName}</td>
                                <td className="border px-4 py-2">{error.column}</td>
                                <td className="border px-4 py-2">{error.row}</td>
                                <td className="border px-4 py-2">{error.columnName}</td>
                                <td className="border px-4 py-2 text-red-600">Falta valor</td>
                                <td className="border px-4 py-2">
                                    <span className="font-mono bg-gray-100 px-2 py-1 rounded">
                                        {error.rowData.map((cell, idx) => (
                                            <span key={idx} className={idx === error.column - 1 ? "text-red-600 font-bold" : ""}>
                                                {cell || "[VACÍO]"} {idx < error.rowData.length - 1 ? " | " : ""}
                                            </span>
                                        ))}
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};