import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import IconButton from "../../../app/components/IconButton";
import { FormCreateActor } from "./FormCreateActor";

import GeneralService from "../../../services/GeneralService";
import { extractFieldsTypepeople } from "../../TercerosCreate/utils/extract";
import { setListMapping, setListMappingActors } from "../../../reducers/importer/reducerImporter";
import { setListActorsSelected, setListQuestions } from "../../../reducers/documents/reducerGenerateDocument";
import { COLLECTION_DOCUMENTS, COLLECTION_PEOPLE } from "../../../constant/constant";
import { OverlayTrigger, Popover } from "react-bootstrap";

export const ConfigMappings = ({ firstRow, collection, typePeople, typeDocument }) => {
    const { list } = useSelector(state => state.typepeopleSlice);
    const { listQuestions } = useSelector(state => state.documentsAutoSlice);
    const { listMapping, listMappingActors } = useSelector(state => state.reducerImporterSlice);
    const { listActorsSelected } = useSelector(state => state.documentsAutoSlice);
    const [column, setColumn] = useState("");
    const [parameterization, setParameterization] = useState("");
    const [codeQuestionId, setCodeQuestionId] = useState("");
    const dispatch = useDispatch();

    const parameterizations = useMemo(() => extractFieldsTypepeople(list.find(typeP => typeP.id === parseInt(typePeople)) ?? []), [typePeople]);

    const loadDataDocument = async () => {
        const service = new GeneralService("questions-documents");
        const res = await service.getList(1000, { documents_auto_id: typeDocument });
        const { questions_documents = [] } = res;
        dispatch(setListQuestions({ value: questions_documents }));

        const serviceActors = new GeneralService("actors-documents");
        const resActors = await serviceActors.getList(1000, { documents_auto_id: typeDocument });
        const { actors_documents = [] } = resActors;
        dispatch(setListActorsSelected({ value: actors_documents }));
    }

    const handleAddMapping = () => {
        if (collection === COLLECTION_PEOPLE) {
            if (!column || !parameterization) return;
            dispatch(setListMapping([...listMapping, {
                collection,
                excel_column: parseInt(column),
                parameterization_id: parseInt(parameterization),
            }]));
            setParameterization("");
        } else {
            if (!column || !codeQuestionId) return;
            dispatch(setListMapping([...listMapping, {
                collection,
                excel_column: parseInt(column),
                code_questions_id: parseInt(codeQuestionId),
            }]));
            setCodeQuestionId("");
        }

        setColumn("");
    }

    const handleAddPerson = (mappingPerson) => {
        dispatch(setListMappingActors([...listMappingActors, mappingPerson]));
    }

    const handleDeleteMapping = (index) => {
        let lista = [...listMapping];
        lista.splice(index, 1);
        dispatch(setListMapping(lista));
    }

    const handleDeleteMappingActors = (index) => {
        let lista = [...listMappingActors];
        lista.splice(index, 1);
        dispatch(setListMappingActors(lista));
    }

    useEffect(() => {
        if (collection === COLLECTION_DOCUMENTS) {
            loadDataDocument();
        }
    }, [collection, typeDocument]);

    return (
        <>
            <h6 className="font-lato fw-bold my-2">{collection === COLLECTION_PEOPLE ? "Configura los campos" : "Configura los diálogos"}</h6>
            <div className="row">
                <div className="col-12 col-lg-4">
                    {
                        collection === COLLECTION_PEOPLE
                            ?
                            <>
                                <label className="fw-bold">Campo parametrización<i className="text-red">*</i></label>
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        <Popover>
                                            <Popover.Header as="h3" className='bg-light-green text-center'>
                                                Campos requeridos
                                            </Popover.Header>
                                            <Popover.Body>
                                                Los campos marcados con asterisco "<i className="text-red fw-bold">*</i>" son obligatorios para poder hacer la importación de forma correcta.
                                            </Popover.Body>
                                        </Popover>
                                    }
                                >
                                    <i className="fa-solid fa-circle-question ms-2 text-light-purple" role='button'></i>
                                </OverlayTrigger>
                                <select
                                    className="form-select dark-green-input mb-1"
                                    value={parameterization}
                                    onChange={(e) => setParameterization(e.target.value)}
                                >
                                    <option value="">Seleccione...</option>
                                    {
                                        parameterizations.map(param => <option
                                            value={param.id}
                                            key={param.id}
                                        >
                                            {param.name} {param.required && <i className="text-red fw-bold">*</i>}
                                        </option>)
                                    }
                                </select>
                            </>
                            :
                            <>
                                <label className="fw-bold">Pregunta<i className="text-red">*</i></label>
                                <select
                                    className="form-select dark-green-input mb-1"
                                    value={codeQuestionId}
                                    onChange={(e) => setCodeQuestionId(e.target.value)}
                                >
                                    <option value="">Seleccione...</option>
                                    {
                                        listQuestions.map(question => <option
                                            key={question.code_questions.id}
                                            value={question.code_questions.id}
                                        >
                                            {question.name}
                                        </option>)
                                    }
                                </select>
                            </>
                    }
                </div>
                <div className="col-12 col-lg-4">
                    <label className="fw-bold">Columna Excel<i className="text-red">*</i></label>
                    <select
                        className="form-select mb-1 dark-green-input"
                        value={column}
                        onChange={(e) => setColumn(e.target.value)}
                    >
                        <option value="">
                            Seleccione...
                        </option>
                        {
                            firstRow?.map((cell, index) => (
                                <option key={index} value={index}>{cell}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="col-12 col-lg-4 d-flex align-items-end">
                    <button title="Añadir mapeo" className="btn btn-success mt-3 d-block mx-auto" type="button" onClick={handleAddMapping}>
                        <i className="fa-solid fa-plus text-white me-2" />
                        Añadir
                    </button>
                </div>
            </div >
            <div className="row mt-3">
                <div className="col">
                    <div className="d-block rounded-3 clip-hide">
                        <table className={`table table-responsive table-header-custom animate__animated animate__fadeIn table-striped`}>
                            <thead>
                                <tr className='font-lato'>
                                    <th></th>
                                    <th>{collection === COLLECTION_PEOPLE ? "Campo parametrización" : "Pregunta"} </th>
                                    <th>Columna Excel</th>
                                    <th className="text-center">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    listMapping.length === 0
                                        ?
                                        <tr>
                                            <td className="text-center" colSpan="4">No has configurado ninguna columna</td>
                                        </tr>
                                        :
                                        listMapping.map((item, index) => (
                                            <tr key={item.id}>
                                                <td></td>
                                                <td>
                                                    {
                                                        collection === COLLECTION_PEOPLE
                                                            ?
                                                            parameterizations.find(p => p.id === parseInt(item.parameterization_id))?.name
                                                            :
                                                            listQuestions.find(q => q.code_questions?.id === parseInt(item.code_questions_id))?.name
                                                    }
                                                </td>
                                                <td>{firstRow?.[item.excel_column]}</td>
                                                <td className="text-center">
                                                    <IconButton
                                                        icon="fa-solid fa-trash-can text-red"
                                                        title="Eliminar mapeo"
                                                        onClick={() => handleDeleteMapping(index)}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {
                collection === COLLECTION_DOCUMENTS
                &&
                <>
                    <h6 className="font-lato fw-bold my-2">Establece las etiquetas</h6>
                    <FormCreateActor firstRow={firstRow} onAddMapping={handleAddPerson} />
                    <div className="row mt-3">
                        <div className="col">
                            <div className="d-block rounded-3 clip-hide">
                                <table className={`table table-responsive table-header-custom animate__animated animate__fadeIn table-striped`}>
                                    <thead>
                                        <tr className='font-lato'>
                                            <th></th>
                                            <th>Columna Excel</th>
                                            <th>Etiqueta</th>
                                            <th className="text-center">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listMappingActors.length === 0
                                                ?
                                                <tr>
                                                    <td className="text-center" colSpan="4">No has añadido ninguna persona</td>
                                                </tr>
                                                :
                                                listMappingActors.map((item, index) => (
                                                    <tr key={item.id}>
                                                        <td></td>
                                                        <td>{firstRow?.[item.excel_column]}</td>
                                                        <td>
                                                            <span className="btn-success text-white p-2 rounded-3 fs-8">
                                                                {
                                                                    listActorsSelected.
                                                                        find(actor => actor.type_peoples_id === item.type_peoples_id)?.
                                                                        labels.find(label => label.id === item.label_global_id)?.name
                                                                }
                                                            </span>
                                                        </td>
                                                        <td className="text-center">
                                                            <IconButton
                                                                icon="fa-solid fa-trash-can text-red"
                                                                title="Eliminar mapeo"
                                                                onClick={() => handleDeleteMappingActors(index)}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}
