import * as XLSX from "xlsx";
import { extractFieldsTypepeople } from "../../TercerosCreate/utils/extract";

export const readExcelFile = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (e) => {
            try {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });

                const sheetName = workbook.SheetNames[0];
                const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });

                const firstTwoRows = worksheet.slice(0, 2);
                resolve({ fileData: firstTwoRows, fileName: file.name, worksheet });
            } catch (err) {
                reject(err);
            }
        };

        reader.onerror = (err) => reject(err);
        reader.readAsArrayBuffer(file);
    });
};

export const validateRequiredFields = (typePeople, listMapping = []) => {
    if (!typePeople) return false;

    const fields = extractFieldsTypepeople(typePeople);
    const filteredFieldsRequired = fields.filter(field => field.required);

    for (let field of filteredFieldsRequired) {
        if (!listMapping.some(item => item.parameterization_id === field.id)) {
            return false;
        }
    }

    return true;
}

export const validateExcelData = (worksheet, listMapping = [], typePeople) => {
    const errors = [];
    const headers = worksheet[0] || []; // Get headers
    const headersLength = headers.length;

    const fields = extractFieldsTypepeople(typePeople);
    const requiredFields = fields.filter(f => f.required);
    const checkColumns = [];
    listMapping.forEach(item => {
        if (requiredFields.some(field => field.id === item.parameterization_id)) {
            checkColumns.push(item.excel_column);
        }
    });

    // Omit table headers
    for (let rowIndex = 1; rowIndex < worksheet.length; rowIndex++) {
        const row = worksheet[rowIndex];

        if (!row || row.length === 0) continue;

        for (let colIndex = 0; colIndex < headersLength; colIndex++) {
            if (!checkColumns.some(item => item === colIndex)) continue;

            const cell = row[colIndex];

            if (cell === null || cell === undefined || cell === "") {
                errors.push({
                    row: rowIndex + 1,
                    column: colIndex + 1,
                    cellName: `${getExcelColumnName(colIndex)}${rowIndex + 1}`,
                    columnName: headers[colIndex] || `Columna ${colIndex + 1}`,
                    missingValue: true,
                    rowData: row
                });
            }
        }
    }

    return errors;
};

const getExcelColumnName = (colIndex) => {
    let columnName = "";
    let index = colIndex;

    while (index >= 0) {
        columnName = String.fromCharCode((index % 26) + 65) + columnName;
        index = Math.floor(index / 26) - 1;
    }

    return columnName;
};