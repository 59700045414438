import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import GeneralService from "../../../services/GeneralService";
import { SearchBar } from "../../../app/components/SearchBar";
import confirmDelete from "../../../utils/confirmDelete";
import { cleanData, deleteFromGroup, orderGroup, reducerFormSearch, setList, setSelected } from "../../../reducers/configuration/reducerExpedientGroups";
import { useAnimateBackground } from "../../../app/hooks/useAnimateBackground";
import { MessageError, showAnimateOrderItem, toast } from "../../../utils";
import IconButton from "../../../app/components/IconButton";
import ModalExpedientGroup from "./ModalExpedientGroup";

export const ExpedientGroups = () => {
    const { listFilter } = useSelector(state => state.expedientGroupsSlice);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const [showEffect, setShowEffect] = useAnimateBackground();
    const service = new GeneralService("expedient-groups");

    const loadGroups = async () => {
        const res = await service.getList();
        const { groups = [] } = res;
        dispatch(setList(groups));
    }

    const onSearch = (e) => {
        dispatch(reducerFormSearch(e.target.value));
    }

    const deleteExpedientGroup = async (id) => {
        const res = await service.delete(id);
        if (res.is_ok) {
            toast(dispatch, res.message);
            dispatch(deleteFromGroup(id));
            return;
        }
        MessageError(res.message, service.status);
    }

    const handleMove = async (id, order) => {
        const service = new GeneralService("expedient-groups/order");
        const res = await service.post({ id, order });
        if (res.is_ok) {
            dispatch(orderGroup({ value: { id, order } }));
            setShowEffect({ show: true, id });
            return;
        }
        MessageError(res.message, service.status);
    }

    useEffect(() => {
        loadGroups();
    }, []);

    return (
        <>
            <div className="col px-3 py-4 animate__animated animate__fadeIn">

                <div className="row center-vertical">
                    <div className="col-12 col-lg-4">
                        <h5 className="font-lato text-green">
                            <i className="fa-solid fa-palette me-2"></i>
                            Grupos de expedientes
                        </h5>
                    </div>

                    <div className="col-8 col-lg-5">
                        <SearchBar onChange={onSearch} />
                    </div>

                    <div className="col-4 col-lg-3">
                        <button title="Crear etiqueta" className="btn btn-success d-block me-4 mt-1 ms-auto" type="button"
                            onClick={() => { dispatch(cleanData()); setShow(true); }}>
                            <i className="fa-solid fa-layer-group text-white"></i>
                            <span className='d-none d-md-inline-block ms-2'>Crear</span>
                        </button>
                    </div>
                </div>

                <div className='row mt-3'>
                    <div className="col-12" style={{ height: "25rem", overflowY: "auto" }}>

                        <div className="d-block rounded-3 clip-hide">
                            <table className="table table-responsive table-header-custom animate__animated animate__fadeIn">
                                <thead>
                                    <tr className="font-lato">
                                        <th></th>
                                        <th>#</th>
                                        <th>Nombre</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listFilter.length === 0 ?
                                            <tr className="p-2 text-center bg-light-green" >
                                                <td colSpan={4}>No hay grupos que mostrar</td>
                                            </tr>
                                            :
                                            listFilter.map((item, i) => (
                                                <tr
                                                    key={item.id}
                                                    className={`font-rubik change-background-animation ${showAnimateOrderItem(item, showEffect)}`}
                                                    onDoubleClick={() => { dispatch(setSelected(item)); setShow(true); }}
                                                >
                                                    <td></td>
                                                    <td style={{width: "40px"}}>{i + 1}.</td>
                                                    <td className="w-75">{item.name}</td>
                                                    <td>
                                                        <IconButton
                                                            icon="fa-solid fa-pen-to-square text-green"
                                                            title="Editar grupo"
                                                            onClick={() => {
                                                                dispatch(setSelected(item));
                                                                setShow(true);
                                                            }}
                                                        />
                                                        <IconButton
                                                            icon="fa-solid fa-trash-can text-red"
                                                            title="Eliminar grupo"
                                                            onClick={async () => await confirmDelete(() => deleteExpedientGroup(item.id))}
                                                        />
                                                        <div className="move-parameterization">
                                                            <i className="fa-solid fa-circle-arrow-up text-green me-2" role='button' onClick={() => handleMove(item.id, (i - 1))}></i>
                                                            <i className="fa-solid fa-circle-arrow-down text-purple" role='button' onClick={() => handleMove(item.id, (i + 1))}></i>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

            </div >
            <ModalExpedientGroup show={show} setShow={setShow} />
        </>
    );
}