import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from 'query-string';

import { Tab, Tabs } from "react-bootstrap";
import BreadcrumbCustom from "../../../../app/components/BreadcrumCustom";
import TabActors from "./TabActors";
import TabQuestions from "./TabQuestions";
import TabText from "./TabText";

import GeneralService from "../../../../services/GeneralService";
import { setCodes, setListActorsSelected, setListQuestions, setSelectedDocument } from "../../../../reducers/documents/reducerGenerateDocument";
import { addToCodeAll, addToCodeLabels, addToCodeNameField, extractActorsCodes } from "../../utils/functions";
import { setList } from "../../../../reducers/configuration/reducerLabelGlobal";
import { BAD_REQUEST } from "../../../../constant/constant";
import { MessageError } from "../../../../utils";
import "./style/parametrize-document.css";

const ParametrizeDocument = () => {
    const { dataDocument, refreshActors, refreshQuestions } = useSelector(state => state.documentsAutoSlice);
    const { list: listTypePeople } = useSelector(state => state.typepeopleSlice);
    const dispatch = useDispatch();
    const { id = 0 } = useParams();
    const { search } = useLocation();
    const { tab = 'actors' } = queryString.parse(search);
    const [key, setKey] = useState(tab);
    const history = useHistory();

    const onSelect = (k) => {
        setKey(k);
        history.push("?tab=" + k);
    }

    const loadDocument = async () => {
        const service = new GeneralService("documents-auto/" + id);
        const document = await service.getRequest();
        if (service.status === BAD_REQUEST) {
            MessageError(document.message);
            setTimeout(() => history.goBack(), 1200);
            return;
        }

        // document.url_template_html = await downloadFile(document.url_template_html);
        dispatch(setSelectedDocument({ value: document }));
    }

    const loadActors = async () => {
        const service = new GeneralService("actors-documents");
        const res = await service.getList(1000, { documents_auto_id: id });
        const { actors_documents = [] } = res;
        dispatch(setListActorsSelected({ value: actors_documents }));

        const codes = [];
        actors_documents.forEach(element => {
            const actor = listTypePeople.find(t => t.id === element.type_peoples_id);
            const tempCodes = extractActorsCodes(actor);
            // Adds Code + #LABEL
            const codesLabel = addToCodeLabels(tempCodes, element.labels);
            // Adds {{ + Code + }}
            const codesAll = addToCodeAll(tempCodes);
            // Adds &NC + Code#LABEL
            const codesNameField = addToCodeNameField(codesLabel);

            codes.push(...codesLabel, ...codesAll, ...codesNameField);
        });
        dispatch(setCodes({ type: "CODES_ACTOR", value: codes }));
    }

    const loadQuestions = async () => {
        const service = new GeneralService("questions-documents");
        const res = await service.getList(1000, { documents_auto_id: id });
        const { questions_documents = [] } = res;
        dispatch(setListQuestions({ value: questions_documents }));
    }

    const loadlabels = async () => {
        const service = new GeneralService("label-global");
        const res = await service.getList(0, { page_size: 1000 });
        const { labels = [] } = res;
        dispatch(setList(labels));
    }

    useEffect(() => {
        loadlabels();
    }, []);

    useEffect(() => {
        loadDocument();
    }, [id, refreshActors]);

    useEffect(() => {
        loadActors();
    }, [dataDocument.id, refreshActors, listTypePeople]);

    useEffect(() => {
        loadQuestions();
    }, [dataDocument.id, refreshQuestions]);

    return (
        <div className="container-fluid max-height-overflow-y mx-2">
            <div className="row">
                <div className="col-11">
                    <BreadcrumbCustom
                        title="Parametrizar documento"
                        routes={[
                            { name: "Documentos", link: "/documents-auto/list" },
                        ]}
                        activeRoute={dataDocument.name ? dataDocument.name : "Seleccione un documento"}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <Tabs
                        id="tab-parametrize-documents"
                        className="mb-3 custom-tabs"
                        activeKey={key}
                        onSelect={onSelect}
                        fill
                    >
                        <Tab
                            eventKey="actors"
                            className="px-2"
                            title={
                                <>
                                    <i className="fa-solid fa-users text-green fs-5"></i>
                                    <h6 className="d-inline-block ms-2 text-black font-rubik fw-bold">Actores</h6>
                                </>
                            }
                        >
                            <TabActors />
                        </Tab>
                        <Tab
                            eventKey="questions"
                            className="px-2"
                            title={
                                <>
                                    <i className="fa-solid fa-question text-green fs-5"></i>
                                    <h6 className="d-inline-block ms-2 text-black font-rubik fw-bold">Diálogos</h6>
                                </>
                            }
                        >
                            <TabQuestions />
                        </Tab>
                        <Tab
                            eventKey="text"
                            className="px-2"
                            title={
                                <>
                                    <i className="fa-solid fa-envelope-open-text text-green fs-5"></i>
                                    <h6 className="d-inline-block ms-2 text-black font-rubik fw-bold">Plantilla</h6>
                                </>
                            }
                        >
                            <TabText change={key} />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}

export default ParametrizeDocument;