import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap"

import { ListExpedient } from "../../../Expedients/generate/components/ListExpedient";
import DocumentsPerPerson from "../../components/DocumentsPerPerson";
import IconButton from "../../../../app/components/IconButton";

import GeneralService from "../../../../services/GeneralService";
import { setList as setListExpedient } from "../../../../reducers/expedient/reducerExpedient";
import { useDispatch } from "react-redux";
import { toast } from "../../../../utils";

export const TabsElatedToPerson = ({ peopleId }) => {
  const [key, setKey] = useState("expedients");
  const dispatch = useDispatch();

  const getExpedients = async () => {
    const service = new GeneralService("expedient/people");
    const res = await service.getList(1000, { people_id: peopleId });
    const { expedients = [] } = res;
    dispatch(setListExpedient(expedients));
  }

  const reload = async () => {
    await getExpedients();
    toast(dispatch, "Los datos han sido recargados");
  }

  useEffect(() => {
    getExpedients();
  }, [peopleId]);

  return (
    <Tabs
      id="tab-people"
      className="mb-3 custom-tabs"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      fill
    >
      <Tab
        eventKey="expedients"
        className="px-2"
        title={
          <>
            <i className="fa-solid fa-folder text-green fs-5"></i>
            <h6 className="d-inline-block ms-2 text-black font-rubik">Expedientes</h6>
          </>
        }
        style={{ minHeight: "35rem" }}
      >
        <div className="row rounded-top bg-green pt-3 pb-2 px-3">
          <div className="col d-flex justify-content-between">
            <h5 className="d-inline-block text-white"><i className="fa-solid fa-folder d-inline-block me-3"></i> Expedientes relacionados</h5>
            <IconButton
              icon="fa-solid fa-arrows-rotate text-white"
              title="Recargar información"
              fontSize={20}
              onClick={reload}
            />
          </div>
        </div>
        <div className="row">
          <div className="col px-0">
            <ListExpedient />
          </div>
        </div>
      </Tab>
      <Tab
        eventKey="documents"
        className="px-2"
        title={
          <>
            <i className="fa-solid fa-file-lines text-green fs-5"></i>
            <h6 className="d-inline-block ms-2 text-black font-rubik">Documentos</h6>
          </>
        }
        style={{ minHeight: "35rem" }}
      >
        <DocumentsPerPerson peopleId={peopleId} />
      </Tab>
    </Tabs>
  )
}
