import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import GeneralService from "../../services/GeneralService";
import { showLoading, hideLoading } from '../../reducers/main/loadingReducer';
import { MessageSuccess, MessageError, isURL } from '../../utils';
import { compressImage, encodeFileBase64 } from "./utils/functions";
import ImageDefaultProfile from '../../assets/profile-pic.svg';
import { setListTypeDocument } from "../../reducers/user/reducerUsers";
import { BAD_REQUEST, HTTP_CREATED } from "../../constant/constant";

export const JoinToBusiness = () => {
    const { listTypeDocument } = useSelector(state => state.userSlice);
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [joinInformation, setJoinInformation] = useState({ name: "", business_name: "" });
    const [urlDocument, setUrlDocument] = useState("");
    const [urlAvatar, setUrlAvatar] = useState("");
    const dispatch = useDispatch();
    const history = useHistory();
    const { uuid } = useParams();

    const verifyUUID = async () => {
        const service = new GeneralService("user/check-uuid-join-business");
        const res = await service.post({ uuid });
        if (res.status === BAD_REQUEST) {
            history.replace("/user-registration/error");
            return;
        }
        setJoinInformation(res);
    }

    const encodeDocument = async (e) => {
        const base64 = await encodeFileBase64(e.target.files[0]);
        if (base64) {
            return setUrlDocument(base64);
        }
        MessageError("No es un documento válido");
    }

    const loadTypeDocument = async () => {
        const service = new GeneralService("type_document");
        const { type_document = [] } = await service.getList();
        dispatch(setListTypeDocument(type_document));
    }

    const encodeImage = async (e) => {
        const compressed = await compressImage(e.target.files[0]);
        if (compressed) {
            const base64 = await encodeFileBase64(compressed);
            if (base64) {
                return setUrlAvatar(base64)
            }
        }
        await MessageError("Imagen muy pesada o formato inválido");
    }

    const showImage = () => {
        return !isURL(urlAvatar) && urlAvatar === ""
            ?
            <img className="animate__animated animate__fadeIn animate__slow" src={ImageDefaultProfile} style={{ width: '100px', height: '100px' }} alt="" />
            :
            <img className="animate__animated animate__fadeIn animate__slow" src={`data:image/png;base64,${urlAvatar}`} style={{ width: '100px', height: '100px' }} alt="" />

    }

    const onSubmit = async (formData) => {
        const service = new GeneralService("/user/accept-invitation");
        const data = {
            uuid,
            profile: {
                ...formData,
                type_document_id: parseInt(formData.type_document_id),
                url_avatar: urlAvatar,
                url_document: urlDocument,
            }
        };

        dispatch(showLoading());
        const res = await service.post(data);
        dispatch(hideLoading());
        if (service.status === HTTP_CREATED) {
            MessageSuccess(res.message);
            history.push(`/user-registration/successful?name=${joinInformation.name}&business_name=${joinInformation.business_name}`);
            return;
        }
        MessageError(res.message);
    };

    useEffect(() => {
        verifyUUID();
        loadTypeDocument();
    }, []);

    return (
        <div className="pt-4 bg-green" style={{ display: "flex", minHeight: "100vh" }}>
            <div className="container-fluid font-lato">
                <div className="row">
                    <div className="col-11 col-sm-11 col-md-10 col-lg-10 mx-auto bg-white rounded-3 p-5">
                        <h5 className="text-center fw-bold pb-2">¡Únete a nuestro equipo de {joinInformation.business_name}!</h5>
                        <p className="text-center">Hola, {joinInformation.name}. Por favor, completa los datos solicitados. Recueda que, los campos marcados con <b><i className="text-red">*</i></b> son obligatorios.</p>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12 table-data form-group">
                                        <div>
                                            <div className="upload mx-auto mb-4">
                                                {showImage()}
                                                <div className="round">
                                                    <input
                                                        type="file"
                                                        accept="image/png,image/jpeg"
                                                        onChange={encodeImage}
                                                    />
                                                    <i className="fa fa-camera" style={{ color: "#fff" }}></i>
                                                </div>
                                            </div>

                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <label>Nombre<b><i className="text-red">*</i></b></label>
                                                        <input
                                                            type="text"
                                                            className="form-control green-input"
                                                            {...register('name', {
                                                                required: 'Nombre es requerido',
                                                                minLength: 3,
                                                                maxLength: 25,
                                                                pattern: {
                                                                    value: /^([a-zA-ZÀ-ÿ\u00f1\u00d1\s]{3,25})$/,
                                                                    message: 'El formato no es correcto'
                                                                }
                                                            })}
                                                            placeholder="Digita nombre(s)"
                                                        />
                                                        {errors.name && <div className="fs-7 text-red text-end">{errors.name.message}</div>}
                                                    </div>

                                                    <div className="col-md-3">
                                                        <label>Apellidos<b><i className="text-red">*</i></b></label>
                                                        <input
                                                            type="text"
                                                            className="form-control green-input"
                                                            {...register('surname', {
                                                                required: 'Apellidos son requeridos',
                                                                minLength: 3,
                                                                maxLength: 25,
                                                                pattern: {
                                                                    value: /^([a-zA-ZÀ-ÿ\u00f1\u00d1\s]{3,25})$/,
                                                                    message: 'El formato no es correcto'
                                                                }
                                                            })}
                                                            placeholder="Digita apellidos"
                                                        />
                                                        {errors.surname && <div className="fs-7 text-red text-end">{errors.surname.message}</div>}
                                                    </div>

                                                    <div className="col-md-6">
                                                        <label>Tipo de documento<b><i className="text-red">*</i></b></label>
                                                        <select
                                                            className="form-select green-input"
                                                            {...register('type_document_id', { required: 'Tipo de documento es requerido' })}
                                                            defaultValue=""
                                                        >
                                                            <option value="">Selecciona...</option>
                                                            {listTypeDocument.map((item) => (
                                                                <option value={item.id} key={item.id}>
                                                                    {item.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {errors.type_document_id && <div className="fs-7 text-red text-end">{errors.type_document_id.message}</div>}
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6 mt-3">
                                                        <label>Numero de documento<b><i className="text-red">*</i></b></label>
                                                        <input
                                                            type="text"
                                                            className="form-control green-input"
                                                            {...register('num_document', {
                                                                required: 'Documento es requerido',
                                                                minLength: 3,
                                                                maxLength: 14,
                                                            })}
                                                            placeholder="Digita número de documento"
                                                        />
                                                        {errors.num_document && <div className="fs-7 text-red text-end">{errors.num_document.message}</div>}
                                                    </div>

                                                    <div className="col-md-6 mt-3" style={{ width: "50%", height: "10px" }}>
                                                        <label>Cargue documento<b><i className="text-red">*</i></b></label>
                                                        <input
                                                            type="file"
                                                            className="form-control green-input"
                                                            accept="application/pdf"
                                                            onChange={encodeDocument}
                                                            required
                                                        />
                                                    </div>

                                                    <div className="col-md-6 mt-3">
                                                        <label>Nombre de usuario<b><i className="text-red">*</i></b></label>
                                                        <input
                                                            type="text"
                                                            className="form-control green-input"
                                                            {...register('nick_name', {
                                                                required: 'Nombre de usuario es requerido',
                                                                minLength: 3,
                                                                maxLength: 50
                                                            })}
                                                            placeholder="Digita el nombre de usuario"
                                                        />
                                                        {errors.nick_name && <div className="fs-7 text-red text-end">{errors.nick_name.message}</div>}
                                                    </div>

                                                    <div className="col-md-6 mt-3">
                                                        <label>Fecha de nacimiento<b><i className="text-red">*</i></b></label>
                                                        <input
                                                            type="date"
                                                            className="form-control green-input"
                                                            {...register('date_birth', { required: 'Fecha de nacimiento es requerida' })}
                                                            placeholder="Digita fecha de nacimiento"
                                                        />
                                                        {errors.date_birth && <div className="fs-7 text-red text-end">{errors.date_birth.message}</div>}
                                                    </div>

                                                    <div className="col-md-3 mt-3">
                                                        <label>Teléfono de contacto<b><i className="text-red">*</i></b></label>
                                                        <input
                                                            type="text"
                                                            className="form-control green-input"
                                                            {...register('phone_contact', {
                                                                required: 'Telefono de contacto es requerido',
                                                            })}
                                                            placeholder="Digita telefono de contacto"
                                                        />
                                                        {errors.phone_contact && <div className="fs-7 text-red text-end">{errors.phone_contact.message}</div>}
                                                    </div>

                                                    <div className="col-md-3 mt-3">
                                                        <label>Teléfono alternativo</label>
                                                        <input
                                                            type="text"
                                                            className="form-control green-input"
                                                            {...register('phone_contact_two')}
                                                            placeholder="Digita teléfono de contacto alternativo"
                                                        />
                                                        {errors.phone_contact_two && <div className="fs-7 text-red text-end">{errors.phone_contact_two.message}</div>}
                                                    </div>

                                                    <div className="col-md-6 mt-3">
                                                        <label>Dirección<b><i className="text-red">*</i></b></label>
                                                        <input
                                                            type="text"
                                                            className="form-control green-input"
                                                            {...register('address', {
                                                                required: 'Dirección es requerida',
                                                                maxLength: 60
                                                            })}
                                                            placeholder="Digita una dirección"
                                                        />
                                                        {errors.address && <div className="fs-7 text-red text-end">{errors.address.message}</div>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-4">
                                                <div className="col">
                                                    <button type="submit" className="btn btn-success rounded-pill d-block mx-auto">
                                                        <i className="fa-solid fa-person-circle-plus me-2"></i>
                                                        Vincularme
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};
