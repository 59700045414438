import { createSlice } from "@reduxjs/toolkit";
import { formatDate } from "../../utils/formatDate";
import { similarActorsInlist } from "../../modules/DocumentsAuto/utils/filters";

const personDocumentSlice = createSlice({
    name: 'personDocument',
    initialState: {
        dataFilter: {
            type_document: 0,
            date_sent: "",
            created_at: "",
            status: "",
            actors: [],
        },
        elatedDocuments: [],
        elatedDocumentsFiltered: [],
    },
    reducers: {
        reducerForm: (state, action) => {
            state.dataFilter[action.payload.key] = action.payload.value;
        },
        fillElatedDocuments: (state, action) => {
            state.elatedDocuments = action.payload.value;
            state.elatedDocumentsFiltered = action.payload.value;
        },
        applyFilters: (state, action) => {
            const { isPerPerson, isNoSigned = false } = action.payload.value;
            let fieldDate = isNoSigned ? "created_at" : "date_sent";

            const typeDocumentFilter = document => parseInt(state.dataFilter.type_document) !== 0 ? document.documents_auto.id === parseInt(state.dataFilter.type_document) : true;

            const dateFilter = document => state.dataFilter[fieldDate] !== "" ? new Date(document[fieldDate]) >= new Date(state.dataFilter[fieldDate]) : true;

            let statusFilter;
            if (isNoSigned) {
                statusFilter = document => {
                    if (state.dataFilter.status === "in_progress" && !document.state_generated) return true;

                    return state.dataFilter.status !== "" ? document.state_generated === statusNosigned[state.dataFilter.status] : true;
                }
            } else {
                statusFilter = document => {
                    if (isPerPerson) {
                        return state.dataFilter.status !== "" ? document.actors[0].status === state.dataFilter.status : true;
                    }
                    return state.dataFilter.status !== "" ? document.status === state.dataFilter.status : true;
                }
            }


            const peopleFilter = document => state.dataFilter.actors.length > 0 ? similarActorsInlist(document.actors, state.dataFilter.actors) === state.dataFilter.actors.length : true

            state.elatedDocumentsFiltered = state.elatedDocuments
                .filter(typeDocumentFilter)
                .filter(dateFilter)
                .filter(statusFilter)
                .filter(peopleFilter);
        },
        clearFilter: (state, action) => {
            state.dataFilter = { type_document: 0, date_sent: "", created_at: "", status: "", actors: [] };
        },
    }
});

const statusNosigned = {
    in_progress: false,
    exported: true,
}

export const {
    reducerForm,
    fillElatedDocuments,
    applyFilters,
    clearFilter,
} = personDocumentSlice.actions;

export default personDocumentSlice.reducer;