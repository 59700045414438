import { Nav } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

const ItemNavigation = ({ path = "", icon = "", title = "", isExpanded, isMobile }) => {
    const location = useLocation();

    const isActive = location.pathname === path;

    return (
        <Nav.Link as={Link} to={path} className={`item__menu ${isActive ? "active": "" } ${!isExpanded ? "not-expanded": ""}`} title={title}>
            {
                isMobile
                    ?
                    <>
                        <i className={`${icon} fs-5 icon__menu`}></i> {title}
                    </>
                    :
                    isExpanded
                        ?
                        <>
                            <i className={`${icon} fs-5 icon__menu ms-2`}></i> {title}
                        </>
                        :
                        <i className={`${icon} fs-5 icon__menu`}></i>
            }
        </Nav.Link>
    );
};

export default ItemNavigation;
