import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion } from "react-bootstrap";

import CardSwitch from "../../../app/components/CardSwitch";
import { changeZapsignOptionActors, reducerFormZapsignOptions } from "../../../reducers/forms/reducerCreateProcessForm";
import { setApplyDefault, setList } from "../../../reducers/configuration/reducerZapsignConfiguration";
import GeneralService from "../../../services/GeneralService";

const ZapsignOptions = () => {
    const { selectedForm } = useSelector(state => state.formSlice);
    const { optionsZapsign } = useSelector(state => state.createProcessFormSlice);
    const { applyDefault, list } = useSelector(state => state.zapsignConfigurationSlice);
    const dispatch = useDispatch();

    const loadZapsignConfiguration = async () => {
        const service = new GeneralService("/zapsign-configuration/" +  selectedForm.business_id);
        const res = await service.getList();
        dispatch(setList({ value: res.configurations }));
    }

    const setDataSwitch = (e) => {
        dispatch(reducerFormZapsignOptions({
            key: e.target.name,
            value: e.target.checked,
        }));
    }

    const setZapsignConfiguration = () => {
        if (applyDefault) {
            dispatch(changeZapsignOptionActors({ value: list }));
        }
    }

    useEffect(() => {
        setZapsignConfiguration();
    }, [applyDefault, list]);

    useEffect(() => {
        loadZapsignConfiguration();
    }, []);

    return (
        <Accordion defaultActiveKey="0" className="p-0 m-0">
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <h5 className="font-lilita-one text-black">
                        <i className="fa-solid fa-signature me-2"></i>
                        Firma electrónica
                    </h5>
                </Accordion.Header>
                <Accordion.Body>
                    <CardSwitch
                        name="apply_default"
                        icon="fa-solid fa-sliders"
                        title="¿Deseas aplicar la configuración por defecto?"
                        checked={applyDefault}
                        className="mb-2"
                        onChange={() => dispatch(setApplyDefault({ value: !applyDefault }))}
                    />
                    <p>Las configuraciones de envío y verificación de firma electrónica son generales, aplica a cada una de las personas involucradas.</p>

                    <div className="row">
                        <div className="col">
                            <div className="d-block ms-2 py-2 px-4 rounded-3" style={{ border: "1px dashed #ccc" }}>
                                <div className="row">
                                    <div className="col">
                                        <p>Formas de verificación</p>
                                        <CardSwitch
                                            icon="fa-solid fa-camera text-green"
                                            name="require_selfie_photo"
                                            title="Selfie"
                                            checked={optionsZapsign.require_selfie_photo}
                                            disabled={applyDefault}
                                            onChange={setDataSwitch}
                                        />
                                        <CardSwitch
                                            icon="fa-solid fa-address-card text-green"
                                            name="require_document_photo"
                                            title="Documento de identidad"
                                            checked={optionsZapsign.require_document_photo}
                                            disabled={applyDefault}
                                            onChange={setDataSwitch}
                                        />
                                    </div>
                                    <div className="col">
                                        <p>Envío automático de firma a:</p>
                                        {/* <CardSwitch
                                            icon="fa-brands fa-whatsapp text-green"
                                            name="automatic_whatsapp"
                                            title="WhatsApp"
                                            checked={optionsZapsign.automatic_whatsapp}
                                            disabled={applyDefault}
                                            onChange={setDataSwitch}
                                        /> */}
                                        <CardSwitch
                                            icon="fa-solid fa-envelope text-green"
                                            name="automatic_mailing"
                                            title="Correo electrónico"
                                            checked={optionsZapsign.automatic_mailing}
                                            disabled={applyDefault}
                                            onChange={setDataSwitch}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default ZapsignOptions;