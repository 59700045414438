import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Header from './layouts/Header';
import Menu from "./layouts/Menu";

import { DashboardRoutes } from "../../routers/DashboardRoutes";
import ToastNotify from "./ToastNotify";
import LoadingCustom from "./LoadingCustom";
import AlertConsumptionModal from "./AlertConsumptionModal";
import { ToastProgress } from "./ToastProgress";
import { NetworkStatus } from "./NetworkStatus";
import { DefaultBusiness } from "./DefaultBusiness";

import GeneralService from "../../services/GeneralService";
import { setTypepeople } from "../../reducers/typePeople/reducerTypepeople";
import { setCompany } from "../../reducers/business/reducerBusiness";
import { setListTypeDocument } from "../../reducers/user/reducerUsers";
import { useAlertConsumption } from "../hooks/useAlertConsumption";
import { setListForm } from "../../reducers/forms/reducerFormSlice";
import { setList as setListTypeProcess } from "../../reducers/expedient/reducerTypeProcess";
import { setBusinessProfiles, setProfile } from "../../reducers/auth/reducerLogin";
import { setList as setListColors } from "../../reducers/configuration/reducerColors";
import "./styles/dashboardLayout.css";

const Home = () => {
    const { show } = useSelector(state => state.toastSlice);
    const { isExpanded } = useSelector(state => state.menuSlice);
    const dispatch = useDispatch();
    useAlertConsumption();

    useEffect(() => {
        loadProfileInformation();
        getTypepeople();
        getTypeDocument();
        loadForms();
        loadTypeProcess();
        loadColors();
    }, []);

    const getTypepeople = async () => {
        const service = new GeneralService("typepeople");
        const { type_peoples = [] } = await service.getList(1000);
        dispatch(setTypepeople({ value: type_peoples }));
    }

    const getTypeDocument = async () => {
        const service = new GeneralService("type_document");
        const data = await service.getList(1000);
        const { type_document = [] } = data;
        dispatch(setListTypeDocument(type_document));
    }

    const loadForms = async () => {
        const service = new GeneralService("form");
        const { form = [] } = await service.getList(1000);
        dispatch(setListForm({ value: form }));
    }

    const loadTypeProcess = async () => {
        const service = new GeneralService("type-process");
        const { type_process = [] } = await service.getList(1000);
        dispatch(setListTypeProcess(type_process));
    }

    const loadColors = async () => {
        const service = new GeneralService("colors");
        const res = await service.getList({ page_size: 1000 });
        const { colors = [] } = res;
        dispatch(setListColors(colors));
    }

    const loadProfileInformation = async () => {
        const service = new GeneralService("user/load-profile");
        const profile = await service.getList(1000);
        dispatch(setProfile(profile));

        const serviceBusiness = new GeneralService("business/" + profile.business_id);
        const response = await serviceBusiness.getRequest();
        dispatch(setCompany({ value: response }));

        const serviceProfiles = new GeneralService("user/profiles");
        const { profile: profiles = [] } = await serviceProfiles.getRequest();
        dispatch(setBusinessProfiles(profiles));
    }

    return (
        <div className={`dashboard__layout ${isExpanded ? "" : "short"}`}>
            <Header onChangeBusiness={loadProfileInformation} />
            <Menu />
            <div className="content__layout">
                <DashboardRoutes />
            </div>

            {show && <ToastNotify />}
            <NetworkStatus />
            <AlertConsumptionModal />
            <DefaultBusiness onChange={loadProfileInformation} />
            <LoadingCustom />
            <ToastProgress />
        </div>
    );
}

export default Home;