import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { MessageError, MessageLevels, MessageSuccess } from "../../../utils/message";
import StorageUser from "../../StorageUser/components/StorageUser";

import { cleanData, reducerForm, setDataGetById } from '../../../reducers/user/reducerUsers';
import serviceUsers from '../services/serviceUsers';
import { compressImage, encodeFileBase64, isURL } from "../utils/functions";
import { TOAST_WARNING, ID_NOT_DEFINED, SHOW } from "../../../constant/constant";
import BreadcrumbCustom from "../../../app/components/BreadcrumCustom";
import ImageDefaultProfile from '../../../assets/profile-pic.svg';
import '../styles/style-users.css';
import { hideLoading, showLoading } from "../../../reducers/main/loadingReducer";
import GeneralService from "../../../services/GeneralService";
import { toast } from "../../../utils";
import { useDownloadImageAsBase64 } from "../../../app/hooks/useDownloadImageAsBase64";
import { useDownloadFile } from "../../../app/hooks/useDownloadFile";

export const FormUser = () => {
    const { listTypeDocument, dataUser } = useSelector(state => state.userSlice);
    const { file, isLoading } = useDownloadImageAsBase64(dataUser.url_avatar);
    const { list } = useSelector(state => state.rolesSlice);
    const [email, setEmail] = useState("");
    const [emailVerified, setEmailVerified] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    let { id = 0 } = useParams();
    const handleDownload = useDownloadFile();

    const setData = async (e) => {
        dispatch(reducerForm({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    useEffect(() => {
        if (list?.length === 0 && listTypeDocument?.length === 0) {
            history.push('/users/list');
            return;
        } else if (list?.length === 0 && listTypeDocument.length !== 0) {
            MessageLevels(id, goRoleOrUsers);
        }
    }, [list, listTypeDocument]);

    useEffect(() => {
        dataUpdate(id);
    }, [id]);

    const goRoleOrUsers = (p = true) => {
        if (p) {
            window.location.pathname = "/roles/list";
        } else {
            history.push("/users/list");
        }
    }

    const dataUpdate = async (id) => {
        if (id !== ID_NOT_DEFINED) {
            const res = await serviceUsers.getById(id);
            if (Object.keys(res).length > 0) {
                dispatch(setDataGetById({ value: res }));
                return;
            }
            MessageError("No es un ID de usuario válido");
            history.push("/users/list");
        }
    }

    const encodeDocument = async (e) => {
        const base64 = await encodeFileBase64(e.target.files[0]);
        if (base64) {
            dispatch(reducerForm({
                key: "url_document",
                value: base64
            }));
            return;
        }
        MessageError("No es un documento válido");
    }

    const encodeImage = async (e) => {
        const compressed = await compressImage(e.target.files[0]);
        if (compressed) {
            const base64 = await encodeFileBase64(compressed);
            if (base64) {
                dispatch(reducerForm({
                    key: "url_avatar",
                    value: base64
                }));
                return;
            }
        }
        await MessageError("Imagen muy pesada o formato inválido");
    }

    const handleSubmitCreateProfile = async (e) => {
        e.preventDefault();
        const data = {
            ...dataUser,
            type_document_id: parseInt(dataUser.type_document_id),
            role_id: parseInt(dataUser.role_id),
        }

        let res;

        dispatch(showLoading());
        if (id === ID_NOT_DEFINED) {
            data['user'] = { email };
            res = await serviceUsers.save(data);
        }
        else res = await serviceUsers.update(data);
        dispatch(hideLoading());
        if (res.is_ok) {
            MessageSuccess(res.message);
            dispatch(cleanData());
            history.push("/users/list");
            return;
        }
        MessageError(res.message);
    }

    const showImage = () => {
        if (id === ID_NOT_DEFINED || !isURL(dataUser.url_avatar)) {
            return dataUser.url_avatar === '' ?
                <img className="animate__animated animate__fadeIn animate__slow" src={ImageDefaultProfile} style={{ width: '100px', height: '100px' }} alt="" />
                :
                <img className="animate__animated animate__fadeIn animate__slow" src={`data:image/png;base64,${dataUser.url_avatar}`} style={{ width: '100px', height: '100px' }} alt="" />
        }
        return (<img className="animate__animated animate__fadeIn animate__slow" src={!isLoading ? file : ImageDefaultProfile} style={{ width: '100px', height: '100px' }} alt={dataUser.name} />);
    }

    const handleVerifiedEmail = (email) => {
        setEmailVerified(true);
        setEmail(email);
    }

    return (
        <div className="container-fluid max-height-overflow-y">
            <div className="row">
                <div className="col">
                    <BreadcrumbCustom
                        title={id === ID_NOT_DEFINED ? "Crear Usuario" : "Editar Usuario"}
                        routes={[
                            { name: "Usuarios", link: "/users/list" },
                        ]}
                        activeRoute={id === ID_NOT_DEFINED ? "Crear Usuario" : "Editar Usuario"}
                    />
                </div>
            </div>
            {
                id === ID_NOT_DEFINED && !emailVerified
                    ?
                    <FormVerifyEmail onVerified={handleVerifiedEmail} onShowForm={setShowForm} />
                    :
                    id !== ID_NOT_DEFINED || showForm
                        ?
                        <form className="mb-3" onSubmit={handleSubmitCreateProfile}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12 table-data form-group" >
                                        <div className="card card-primary p-4">

                                            <div className="upload ">
                                                {showImage()}
                                                <div className="round">
                                                    <input
                                                        type="file"
                                                        accept="image/png,image/jpeg"
                                                        onChange={encodeImage}
                                                    />
                                                    <i className="fa fa-camera" style={{ color: "#fff" }}></i>
                                                </div>
                                            </div>

                                            <div className="card-body">
                                                <div className="row">

                                                    <div className="col-md-3">
                                                        <label>Nombre<b><i className="text-red">*</i></b></label>
                                                        <input
                                                            type="text"
                                                            className="form-control green-input"
                                                            name="name"
                                                            placeholder="Digita nombre(s)"
                                                            value={dataUser.name}
                                                            minLength="3"
                                                            maxLength="25"
                                                            pattern="^([a-zA-ZÀ-ÿ\u00f1\u00d1\s]{3,25})$"
                                                            onChange={setData}
                                                            required
                                                        />
                                                    </div>

                                                    <div className="col-md-3">
                                                        <label>Apellido<b><i className="text-red">*</i></b></label>
                                                        <input
                                                            type="text"
                                                            className="form-control green-input"
                                                            name="surname"
                                                            placeholder="Digita apellido(s)"
                                                            value={dataUser.surname}
                                                            minLength="3"
                                                            maxLength="25"
                                                            pattern="^([a-zA-ZÀ-ÿ\u00f1\u00d1\s]{3,25})$"
                                                            onChange={setData}
                                                            required
                                                        />
                                                    </div>

                                                    <div className="col-md-6">
                                                        <label>Tipo de documento<b><i className="text-red">*</i></b></label>
                                                        <select
                                                            className="form-select green-input"
                                                            name="type_document_id"
                                                            value={dataUser.type_document_id}
                                                            onChange={setData}
                                                            required
                                                        >
                                                            <option value="">
                                                                Selecciona el tipo de documento
                                                            </option>
                                                            {
                                                                listTypeDocument.map((item) => (
                                                                    <option value={item.id} key={item.id}>
                                                                        {item.name}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6 mt-3">
                                                        <label>Numero de documento<b><i className="text-red">*</i></b></label>
                                                        <input
                                                            type="text"
                                                            className="form-control green-input"
                                                            name="num_document"
                                                            placeholder="Digita número de documento"
                                                            value={dataUser.num_document}
                                                            maxLength="12"
                                                            onChange={setData}
                                                            required
                                                        />
                                                    </div>

                                                    {
                                                        id === ID_NOT_DEFINED && !isURL(dataUser.url_document)
                                                            ?
                                                            <div className="col-md-6 mt-3" style={{ width: "50%", height: "10px" }}>
                                                                <label>Cargue documento<b><i className="text-red">*</i></b></label>
                                                                <input
                                                                    type="file"
                                                                    className="form-control green-input"
                                                                    accept='application/pdf'
                                                                    onChange={encodeDocument}
                                                                    required={id === 0}
                                                                />
                                                            </div>
                                                            :
                                                            <>
                                                                <div className="col-md-5 mt-3" style={{ height: "10px" }}>
                                                                    <label>Cargue documento<b><i className="text-red">*</i></b></label>
                                                                    <input
                                                                        type="file"
                                                                        className="form-control green-input"
                                                                        accept='application/pdf'
                                                                        onChange={encodeDocument}
                                                                        required={id === 0}
                                                                    />
                                                                </div>
                                                                <div role="button" className="col-md-1 pt-5" onClick={()=> handleDownload(dataUser.url_document)}>
                                                                    <b>Ver actual</b>
                                                                </div>
                                                            </>
                                                    }

                                                    <div className="col-md-6 mt-3">
                                                        <label>Nombre de usuario<b><i className="text-red">*</i></b></label>
                                                        <input
                                                            type="text"
                                                            className="form-control green-input"
                                                            name="nick_name"
                                                            placeholder="Digita nombre de usuario"
                                                            value={dataUser.nick_name}
                                                            minLength="2"
                                                            maxLength="50"
                                                            onChange={setData}
                                                            required
                                                        />
                                                    </div>

                                                    <div className="col-md-6 mt-3">
                                                        <label>Rol<b><i className="text-red">*</i></b></label>
                                                        <select
                                                            className="form-select green-input"
                                                            name="role_id"
                                                            value={dataUser.role_id}
                                                            onChange={setData}
                                                            required
                                                        >
                                                            <option value="">
                                                                Seleccione...
                                                            </option>
                                                            {
                                                                list.map(role => (<option value={role.id} key={role.id}>
                                                                    {role.name}
                                                                </option>))
                                                            }
                                                        </select>
                                                    </div>

                                                    <div className="col-md-6 mt-3">
                                                        <label>Email</label>
                                                        <input
                                                            type="email"
                                                            className="form-control green-input"
                                                            name="email"
                                                            placeholder="Digita coreo electrónico"
                                                            value={id === ID_NOT_DEFINED ? email : dataUser.user?.email}
                                                            readOnly
                                                        />
                                                    </div>

                                                    <div className="col-md-6 mt-3">
                                                        <label>Fecha de nacimiento<b><i className="text-red">*</i></b></label>
                                                        <input
                                                            type="date"
                                                            className="form-control green-input"
                                                            name="date_birth"
                                                            placeholder="Digita fecha de nacimientoo"
                                                            value={dataUser.date_birth}
                                                            onChange={setData}
                                                            required
                                                        />
                                                    </div>

                                                    <div className="col-md-3 mt-3">
                                                        <label>Telefono de contacto<b><i className="text-red">*</i></b></label>
                                                        <input
                                                            type="text"
                                                            className="form-control green-input"
                                                            name="phone_contact"
                                                            placeholder="Digita telefono de contacto"
                                                            value={dataUser.phone_contact}
                                                            onChange={setData}
                                                            pattern="^[0-9]{0,10}$"
                                                            required
                                                        />
                                                    </div>

                                                    <div className="col-md-3 mt-3">
                                                        <label>Telefono de contacto 2</label>
                                                        <input
                                                            type="text"
                                                            className="form-control green-input"
                                                            name="phone_contact_two"
                                                            placeholder="Digita teléfono de contacto alternativo"
                                                            value={dataUser.phone_contact_two}
                                                            onChange={setData}
                                                            pattern="^[0-9]{0,10}$"
                                                        />
                                                    </div>

                                                    <div className="col-md-6 mt-3">
                                                        <label>Dirección<b><i className="text-red">*</i></b></label>
                                                        <input
                                                            type="text"
                                                            className="form-control green-input"
                                                            name="address"
                                                            maxLength={60}
                                                            placeholder="Digita una dirección"
                                                            value={dataUser.address}
                                                            onChange={setData}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <hr />
                                            <div className="row">
                                                <div className="col">
                                                    <button className='btn btn-success rounded-pill d-block mx-auto' type='submit'>
                                                        <i className="fa-solid fa-person-circle-plus me-2" style={{ fontSize: '16px' }}></i>
                                                        {id === ID_NOT_DEFINED ? "Crear" : "Editar"}
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </form>
                        :
                        <FormSendLinkInvitationJoin email={email} setShowForm={setShowForm} />
            }
            {
                id !== ID_NOT_DEFINED &&
                <div className="row m-0 mt-4">
                    <StorageUser />
                </div>
            }
        </div>
    );

}

const FormVerifyEmail = ({ onVerified, onShowForm }) => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const dispatch = useDispatch();

    const handleVerificationEmail = async (data) => {
        onVerified(data.email);
        const service = new GeneralService("user/is-available");
        const { is_ok = false } = await service.post(data);
        if (is_ok) {
            onShowForm(SHOW);
            toast(dispatch, "El email está disponible")
            return;
        }
        toast(dispatch, "Email no disponible", TOAST_WARNING);
    }

    return (
        <form onSubmit={handleSubmit(handleVerificationEmail)}>
            <div className="container-fluid py-2">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="row">
                            <h5 className="font-lato fw-bold">Verificar email</h5>
                            <p>Para registrar un nuevo usuario, debes primero verificar si el correo existe o no.</p>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>Verificar email<b><i className="text-red">*</i></b></label>
                                <input
                                    type="email"
                                    className="form-control green-input"
                                    {...register('email', { required: 'Email es requerido', minLength: 3, maxLength: 100 })}
                                    placeholder="Digita correo electrónico"
                                />
                                {errors.email && <div className="fs-7 text-red text-end">{errors.email.message}</div>}
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col">
                                <button type='submit' className='btn btn-success rounded-pill d-block mx-auto'>
                                    <i className="fa-solid fa-magnifying-glass-arrow-right me-2"></i>
                                    Verificar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

const FormSendLinkInvitationJoin = ({ email, setShowForm }) => {
    const { list } = useSelector(state => state.rolesSlice);
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSending = async (data) => {
        const service = new GeneralService("user/send-invitation");
        const res = await service.post({ email, ...data, role_id: parseInt(data.role_id) });
        if (res.is_ok) {
            setShowForm(SHOW);
            toast(dispatch, res.message);
            history.push('/users/list');
            return;
        }
        MessageError(res.message);
    }

    return (
        <form className="mb-3" onSubmit={handleSubmit(handleSending)}>
            <div className="container-fluid py-2">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="row">
                            <h5 className="font-lato fw-bold">Enviar link de invitación</h5>
                            <p>El email no se encuentra disponible, pero puedes enviar una invitación. Cuando envías un link de invitación para este nuevo usuario y el acepte, se creará un perfil vinculado a tu empresa.</p>
                        </div>
                        <div className="row mt-1">
                            <div className="col">
                                <label>Correo<b><i className="text-red">*</i></b></label>
                                <div className="form-control green-input">{email}</div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <label>Nombre<b><i className="text-red">*</i></b></label>
                                <input
                                    type="text"
                                    className="form-control green-input"
                                    {
                                    ...register('name_user', {
                                        required: 'Nombre es requerido', minLength: 3, maxLength: 25,
                                        pattern: {
                                            value: /^([a-zA-ZÀ-ÿ\u00f1\u00d1\s]{3,25})$/,
                                            message: 'El formato no es correcto'
                                        }
                                    })}
                                    placeholder="Digita nombre(s)"
                                />
                                {errors.name_user && <div className="fs-7 text-red text-end">{errors.name_user.message}</div>}
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <label>Rol<b><i className="text-red">*</i></b></label>
                                <select className="form-select green-input" {...register('role_id', { required: 'Rol es requerido' })}>
                                    <option value="">
                                        Seleccione...
                                    </option>
                                    {
                                        list.map(role => (<option value={role.id} key={role.id}>
                                            {role.name}
                                        </option>))
                                    }
                                </select>
                                {errors.role_id && <div className="fs-7 text-red text-end">{errors.role_id.message}</div>}
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col">
                                <button className='btn btn-success rounded-pill d-block mx-auto' type='submit'>
                                    <i className="fa-solid fa-link me-2"></i>
                                    Enviar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}